import { useQuery } from "@tanstack/react-query";
import { Configuration, SectorDomainApi } from "../openapi";

const fetchSectorListFundamental = async () => {
  const configuration = new Configuration({
    basePath: process.env.REACT_APP_API_URL,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  });

  try {
    const sectorListFundamentalApi = new SectorDomainApi(configuration);
    const response = await sectorListFundamentalApi.getSectors("", 0);
    return response.data.content!;
  } catch (error) {
    console.error("Error fetching Fundamental Sector List:", error);
    throw error;
  }
};

export const useSectorListFundamental = () => {
  const {
    data: sectorListFundamental = [],
    isLoading: loadingSectorListFundamental,
    error,
  } = useQuery({
    queryKey: ["sectorListFundamental"],
    queryFn: fetchSectorListFundamental,
    staleTime: 10 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return { sectorListFundamental, loadingSectorListFundamental, error };
};
