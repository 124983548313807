import { useQuery } from "@tanstack/react-query";
import { Configuration, NoteSourceTypeDomainApi } from "../openapi";

const fetchSourceTypes = async () => {
  const configuration = new Configuration({
    basePath: process.env.REACT_APP_API_URL,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  });

  const sourceTypeApi = new NoteSourceTypeDomainApi(configuration);
  const response = await sourceTypeApi.getNoteSourceType("", 0);
  return response.data.content;
};

export const useSourceType = () => {
  const {
    data: sourceType,
    isLoading: loadingSourceType,
    error,
  } = useQuery({
    queryKey: ["sourceTypes"],
    queryFn: fetchSourceTypes,
  });

  return { sourceType, loadingSourceType, error };
};
