import { useQuery } from "@tanstack/react-query";
import { Configuration, SectorDomainApi } from "../openapi";

const fetchSectorListMna = async () => {
  const configuration = new Configuration({
    basePath: process.env.REACT_APP_API_URL,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  });

  try {
    const sectorListMnaApi = new SectorDomainApi(configuration);
    const response = await sectorListMnaApi.getSectors("", 0, 20);
    return response.data.content!;
  } catch (error) {
    throw error;
  }
};

export const useSectorListMna = () => {
  const {
    data: sectorListMna = [],
    isLoading: loadingSectorListMna,
    error: errorSectorListMna,
  } = useQuery({
    queryKey: ["sectorListMna"],
    queryFn: fetchSectorListMna,
    staleTime: 10 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return { sectorListMna, loadingSectorListMna, errorSectorListMna };
};
