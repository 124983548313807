import { useQuery } from "@tanstack/react-query";
import { ConditionCategoryDomainApi, Configuration } from "../openapi";
import { expiredTokenValidation } from "../api/expiredTokenValidation";

const fetchConditionCategories = async () => {
  const configuration = new Configuration({
    basePath: process.env.REACT_APP_API_URL,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  });

  try {
    const conditionCategoryApi = new ConditionCategoryDomainApi(configuration);
    const response = await conditionCategoryApi.getConditionCategories(
      "",
      0,
      undefined
    );

    return (
      response.data.content?.map((item: any) => ({
        id: item.id,
        name: item.name,
      })) || []
    );
  } catch (err: any) {
    console.error("Error fetching Condition Categories:", err);
    expiredTokenValidation(err);
    throw new Error("Failed to load Condition Categories");
  }
};

export const useConditionCategories = () => {
  const {
    data: conditionCategories = [],
    isLoading: loadingConditions,
    error,
    refetch,
  } = useQuery({
    queryKey: ["conditionCategories"],
      queryFn: fetchConditionCategories,
  });

  return {
    conditionCategories,
    loadingConditions,
    error,
    refetch,
  };
};
