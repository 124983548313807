import { formatModifiedDate, checkAlertName } from "./AlertUtils";
import ArrowBeforeAfter from "../../assets/svg/alerts/ArrowBeforeAfter.svg";
import { AlertDiffResponse } from "../../openapi";
import { Link, useLocation } from "react-router-dom";
import { useConditionCategories } from "../../hooks/useConditionCategories";
import { useSourceType } from "../../hooks/useSourceType";
import { useSectorListFundamental } from "../../hooks/useSectorListFundamental";
import { useMnaCategory } from "../../hooks/useMnaCategory";
import { useLinksCategories } from "../../hooks/useLinksCategories";
import { useSectorListMna } from "../../hooks/useSectorListMna";
import { useFundamental } from "../../hooks/useFundamental";
import { useMna } from "../../hooks/useMna";

interface AlertDiffProps {
  alert: AlertDiffResponse;
  diffItem: any;
  diffIndex: number;
}

const AlertDiffContainer: React.FC<AlertDiffProps> = ({
  alert,
  diffItem,
  diffIndex,
}) => {
  const location = useLocation();
  const isFundamental =
    diffItem.name === "fundamentalTarget" ||
    diffItem.name === "fundamentalAcquirer";
  const isMna = diffItem.name === "mna";

  const { data: fundamentalBefore } = useFundamental(
    isFundamental ? diffItem?.before?.cdoId : undefined
  );
  const { data: fundamentalAfter } = useFundamental(
    isFundamental ? diffItem?.after?.cdoId : undefined
  );

  const { data: mnaBefore } = useMna(
    isMna ? diffItem?.before?.cdoId : undefined
  );
  const { data: mnaAfter } = useMna(isMna ? diffItem?.after?.cdoId : undefined);

  const { conditionCategories } = useConditionCategories();
  const { sourceType } = useSourceType();
  const { sectorListFundamental } = useSectorListFundamental();
  const { mnaCategory } = useMnaCategory();
  const { linksCategories } = useLinksCategories();
  const { sectorListMna } = useSectorListMna();

  return (
    <>
      <div className="flex">
        <div className="w-[45%]">
          {diffIndex === 0 && <p className="text-xs-normal mb-1">Before</p>}
        </div>
        <div className="w-[10%]"> </div>
        <div className="w-[45%]">
          {diffIndex === 0 && <p className="text-xs-normal mb-1">After</p>}
        </div>
      </div>
      <div className="flex justify-between mb-2" key={diffItem.name}>
        <div className="w-[45%]">
          <div className="border-1 rounded-lg p-2">
            <p className="text-xs-medium text-gray-500">
              <span className="inline-block mr-[18px]">{diffItem.name}</span>
              <span>
                <span>
                  {(() => {
                    const createDOMPurify = require("dompurify");
                    const DOMPurify = createDOMPurify(window);
                    if (
                      diffItem.name === "description" ||
                      diffItem.name === "content" ||
                      diffItem.name === "businessDesc" ||
                      diffItem.name === "companyHistory" ||
                      diffItem.name === "driversValuation" ||
                      diffItem.name === "valuationHistory" ||
                      diffItem.name === "companyBasics" ||
                      diffItem.name === "note" ||
                      diffItem.name === "process" ||
                      diffItem.name === "commentary"
                    ) {
                      const sanitizedHtml = DOMPurify.sanitize(diffItem.before!)
                        .replace(
                          /<pre[^>]*>/gi,
                          '<div style="white-space: pre-wrap; background: #f5f5f5; padding: 10px; border-radius: 4px;">'
                        )
                        .replace(
                          /<img/gi,
                          '<img style="max-width: 100%; height: auto; margin: 8px 0;" '
                        );

                      return (
                        <span
                          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                        />
                      );
                    } else if (
                      diffItem.name === "fundamentalTarget" ||
                      diffItem.name === "fundamentalAcquirer"
                    ) {
                      if (!diffItem?.before?.cdoId) {
                        return "";
                      }
                      return fundamentalBefore ? (
                        <Link
                          className="text-gray-500 hover:text-blue-500"
                          to={`/fundamental/${diffItem.before.cdoId}`}
                          state={{ from: location }}
                        >
                          {fundamentalBefore.name}
                        </Link>
                      ) : (
                        <span>...</span>
                      );
                    } else if (diffItem.name === "mna") {
                      if (!diffItem?.before?.cdoId) {
                        return "";
                      }
                      return mnaBefore ? (
                        <Link
                          className="text-gray-500 hover:text-blue-500"
                          to={`/mna/${diffItem.before.cdoId}`}
                          state={{ from: location }}
                        >
                          {mnaBefore.name}
                        </Link>
                      ) : (
                        <span>...</span>
                      );
                    } else {
                      return checkAlertName(
                        alert.domainTypeId!,
                        diffItem.name!,
                        diffItem.before!,
                        conditionCategories,
                        sourceType,
                        sectorListFundamental,
                        mnaCategory,
                        linksCategories,
                        sectorListMna
                      );
                    }
                  })()}
                </span>
              </span>
            </p>
            <div className="text-xs-medium text-gray-500 flex justify-between mt-2">
              <p>{alert.user?.username}</p>
              <p>
                <span className="inline-block mr-2">
                  {formatModifiedDate(alert.dateCreated!)}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="w-[10%] flex flex-col items-center  ">
          <img
            className="mx-2 mt-6"
            src={ArrowBeforeAfter}
            alt="beforeAfterArrow"
          />
        </div>
        <div className="w-[45%]">
          <div className="border-1 rounded-lg p-2">
            <p className="text-xs-medium text-gray-500">
              <span className="inline-block mr-[10px] ">{diffItem.name}:</span>
              <span>
                {(() => {
                  const createDOMPurify = require("dompurify");
                  const DOMPurify = createDOMPurify(window);
                  if (
                    diffItem.name === "description" ||
                    diffItem.name === "content" ||
                    diffItem.name === "description" ||
                    diffItem.name === "content" ||
                    diffItem.name === "businessDesc" ||
                    diffItem.name === "companyHistory" ||
                    diffItem.name === "driversValuation" ||
                    diffItem.name === "valuationHistory" ||
                    diffItem.name === "companyBasics" ||
                    diffItem.name === "note" ||
                    diffItem.name === "process" ||
                    diffItem.name === "commentary"
                  ) {
                    const sanitizedHtml = DOMPurify.sanitize(diffItem.after!)
                      .replace(
                        /<pre[^>]*>/gi,
                        '<div style="white-space: pre-wrap; background: #f5f5f5; padding: 10px; border-radius: 4px;">'
                      )
                      .replace(
                        /<img/gi,
                        '<img style="max-width: 100%; height: auto; margin: 8px 0;" '
                      );

                    return (
                      <span
                        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                      />
                    );
                  } else if (
                    diffItem.name === "fundamentalTarget" ||
                    diffItem.name === "fundamentalAcquirer"
                  ) {
                    if (!diffItem?.after?.cdoId) {
                      return "";
                    }
                    return fundamentalAfter ? (
                      <Link
                        className="text-gray-500 hover:text-blue-500"
                        to={`/fundamental/${diffItem.after.cdoId}`}
                        state={{ from: location }}
                      >
                        {fundamentalAfter.name}
                      </Link>
                    ) : (
                      <span>...</span>
                    );
                  } else if (diffItem.name === "mna") {
                    if (!diffItem?.after?.cdoId) {
                      return "";
                    }
                    return mnaAfter ? (
                      <Link
                        className="text-gray-500 hover:text-blue-500"
                        to={`/mna/${diffItem.after.cdoId}`}
                        state={{ from: location }}
                      >
                        {mnaAfter.name}
                      </Link>
                    ) : (
                      <span>...</span>
                    );
                  } else {
                    return checkAlertName(
                      alert.domainTypeId!,
                      diffItem.name!,
                      diffItem.after!,
                      conditionCategories,
                      sourceType,
                      sectorListFundamental,
                      mnaCategory,
                      linksCategories,
                      sectorListMna
                    );
                  }
                })()}
              </span>
            </p>
            <div className="text-xs-normal text-gray-500 flex justify-between mt-2">
              <p>{alert.user?.username}</p>
              <p>
                <span className="inline-block mr-2">
                  {formatModifiedDate(alert.dateCreated!)}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertDiffContainer;
