import { useQuery } from "@tanstack/react-query";
import { fetchMnaData } from "../pages/MnaDetails/services/mnaService";

export function useMna(cdoId: string | undefined) {
  return useQuery({
    queryKey: ["mna", cdoId],
    queryFn: () => {
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      if (!cdoId || !uuidRegex.test(cdoId)) {
        return null;
      }
      return fetchMnaData(cdoId);
    },
    enabled: !!cdoId,
    staleTime: 10 * 60 * 1000, 
    gcTime: 30 * 60 * 1000, 
    retry: 1, 
    refetchOnWindowFocus: false, 
    refetchOnMount: false,
  });
}
