// React
import React, { useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
// Components
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import CompanyInformation from "./CompanyInformation";
import PriceTarget from "./PriceTarget";
import CapitalizationSummary from "./CapitalizationSummary";
import ValuationSummary from "./ValuationSummary";
import AttachedFiles from "./AttachedFiles";
import AboutFundamental from "./AboutFundamental";
import TradingData from "./TradingData";
import KeyDates from "./KeyDates";
import Management from "./Management";
import KeyHolders from "./KeyHolders";
import FinancialSummary from "./FinancialSummary";
import Notes from "./Notes";
import Loader from "../../../../components/Loader";
import EditableFieldFundamental from "../../../../components/EditableFields/EditableFieldFundamental";
// Images
import fundamentalIcon from "../../../../assets/svg/blue-fundamental.svg";
import Expand from "../../../../assets/svg/expand.svg";
// Types
import { HandleMenuProps } from "../../../../interfaces/pages/variedInterfaces";
// Services
import { fundamentalServices } from "../../services/fundamentalServices";
// Hooks
import useFileDownload from "../../../../hooks/useFileDownload";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import {
  setIsAllowedToExpandWhatMattersFundamental,
  setIsAllowedToExpandBussinesDescriptionFundamental,
  setIsAllowedToExpandCompanyHistoryFundamental,
  setIsAllowedToExpandCompanyBasicsFundamental,
  setIsAllowedToExpandDriversFundamental,
  setIsAllowedToExpandValuationHistoryFundamental,
} from "../../../../features/textEditorExpand";

const FundamentalDetails: React.FC<HandleMenuProps> = ({ isMenuOpen }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { handleFileDownload } = useFileDownload();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [content, setContent] = useState<string | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sectorList, setSectorList] = useState<any>([]);
  const [notes, setNotes] = useState<any>();
  const [isTextEditorExpanded, setTextEditorIsExpanded] = useState(false);

  const dispatch = useDispatch();
  const isAllowedToExpandWhatMattersFundamental = useSelector(
    (state: RootState) => {
      return state.textEditorExpand.isAllowedToExpandWhatMattersFundamental;
    }
  );
  const isAllowedToExpandBussinesDescriptionFundamental = useSelector(
    (state: RootState) => {
      return state.textEditorExpand
        .isAllowedToExpandBussinesDescriptionFundamental;
    }
  );

  const isAllowedToExpandCompanyHistoryFundamental = useSelector(
    (state: RootState) => {
      return state.textEditorExpand.isAllowedToExpandCompanyHistoryFundamental;
    }
  );

  const isAllowedToExpandCompanyBasicsFundamental = useSelector(
    (state: RootState) => {
      return state.textEditorExpand.isAllowedToExpandCompanyBasicsFundamental;
    }
  );

  const isAllowedToExpandDriversFundamental = useSelector(
    (state: RootState) => {
      return state.textEditorExpand.isAllowedToExpandDriversFundamental;
    }
  );

  const isAllowedToExpandValuationHistoryFundamental = useSelector(
    (state: RootState) => {
      return state.textEditorExpand
        .isAllowedToExpandValuationHistoryFundamental;
    }
  );

  function handleCancelEdit() {
    dispatch(setIsAllowedToExpandWhatMattersFundamental(false));
    dispatch(setIsAllowedToExpandBussinesDescriptionFundamental(false));
    dispatch(setIsAllowedToExpandCompanyHistoryFundamental(false));
    dispatch(setIsAllowedToExpandCompanyBasicsFundamental(false));
    dispatch(setIsAllowedToExpandDriversFundamental(false));
    dispatch(setIsAllowedToExpandValuationHistoryFundamental(false));
  }

  useEffect(() => {
    return () => {
      handleCancelEdit();
    };
  }, [dispatch]);

  useEffect(() => {
    fetchSectorList();
  }, []);

  useEffect(() => {
    fetchFundamentalData();
  }, [id]);

  useEffect(() => {
    if (isTextEditorExpanded) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isTextEditorExpanded]);

  const openModal = (content: string) => {
    setContent(content);
    setIsModalOpen(true);
  };

  const fetchFundamentalData = async () => {
    setLoading(true);
    try {
      const data = await fundamentalServices.fetchFundamentalData(String(id));
      setData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchSectorList = async () => {
    try {
      const options = await fundamentalServices.fetchSectorList(String(id));
      setSectorList(options);
    } catch (error) {}
  };

  const tabsContent = [
    {
      header: "What Matters",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md h-full">
            <div className="px-3.5 pt-4 text-lg-semibold text-blue-500 flex items-center justify-between rounded-tl-md rounded-tr-md">
              <span>What Matters</span>
              {isAllowedToExpandWhatMattersFundamental ? (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    const shouldExpand =
                      !isAllowedToExpandWhatMattersFundamental;
                    dispatch(
                      setIsAllowedToExpandWhatMattersFundamental(shouldExpand)
                    );
                    setTextEditorIsExpanded((prev: boolean) => !prev);
                  }}
                >
                  <img src={Expand} alt="expand" />
                </div>
              ) : null}
            </div>
            <div
              className={`${
                isTextEditorExpanded
                  ? `fixed bg-white top-[92px] right-[16px] rounded-radius-lg duration-500 transition-[width]  ${
                      isMenuOpen
                        ? "w-[calc(100%-316px)] "
                        : "w-[calc(100%-92px)]"
                    }   z-[999] `
                  : "px-2 pb-2"
              }`}
            >
              {data.whatMattersId === null ? (
                <>
                  {isTextEditorExpanded ? (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        const shouldExpand =
                          !isAllowedToExpandWhatMattersFundamental;
                        dispatch(
                          setIsAllowedToExpandWhatMattersFundamental(
                            shouldExpand
                          )
                        );
                        setTextEditorIsExpanded((prev: boolean) => !prev);
                      }}
                    >
                      <img src={Expand} alt="expand" />
                    </div>
                  ) : null}
                  <EditableFieldFundamental
                    data={data.whatMatters}
                    fieldtype="textEditor"
                    fieldName="whatMatters"
                    fetchFundamentalData={fetchFundamentalData}
                    whatMatterId={null}
                    isTextEditorExpanded={isTextEditorExpanded}
                    setTextEditorIsExpanded={setTextEditorIsExpanded}
                    onCancel={handleCancelEdit}
                  />
                </>
              ) : (
                <div className={`${isTextEditorExpanded ? "px-2 pb-2" : ""}`}>
                  {isTextEditorExpanded ? (
                    <div className="flex justify-between text-left text-lg-medium text-blue-500 pt-4 px-2">
                      <span>What Matters</span>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          const shouldExpand =
                            !isAllowedToExpandWhatMattersFundamental;
                          dispatch(
                            setIsAllowedToExpandWhatMattersFundamental(
                              shouldExpand
                            )
                          );
                          setTextEditorIsExpanded((prev: boolean) => !prev);
                        }}
                      >
                        <img src={Expand} alt="expand" />
                      </div>
                    </div>
                  ) : null}
                  <EditableFieldFundamental
                    data={data.whatMatters}
                    fieldtype="textEditor"
                    fieldName="whatMatters"
                    fetchFundamentalData={fetchFundamentalData}
                    whatMatterId={data.whatMattersId}
                    isTextEditorExpanded={isTextEditorExpanded}
                    setTextEditorIsExpanded={setTextEditorIsExpanded}
                    onCancel={handleCancelEdit}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Business Description",
      content: (
        <div className="border border-[#E4E3ED] rounded-md h-full">
          <div className="px-3.5 pt-4 text-lg-semibold text-blue-500 flex items-center justify-between rounded-tl-md rounded-tr-md">
            <span>Business Description</span>
            {isAllowedToExpandBussinesDescriptionFundamental ? (
              <div
                className="cursor-pointer"
                onClick={() => {
                  const shouldExpand =
                    !isAllowedToExpandBussinesDescriptionFundamental;
                  dispatch(
                    setIsAllowedToExpandBussinesDescriptionFundamental(
                      shouldExpand
                    )
                  );
                  setTextEditorIsExpanded((prev: boolean) => !prev);
                }}
              >
                <img src={Expand} alt="expand" />
              </div>
            ) : null}
          </div>
          <div
            className={`${
              isTextEditorExpanded
                ? `fixed bg-white top-[92px] right-[16px] rounded-radius-lg duration-500 transition-[width]  ${
                    isMenuOpen ? "w-[calc(100%-316px)] " : "w-[calc(100%-92px)]"
                  }   z-[999] `
                : "px-2 pb-2"
            }`}
          >
            <div className={`${isTextEditorExpanded ? "px-2 pb-2" : ""}`}>
              {isTextEditorExpanded ? (
                <div className="flex justify-between text-left text-lg-medium text-blue-500 pt-4 px-2">
                  <span>Business Description</span>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      const shouldExpand =
                        !isAllowedToExpandBussinesDescriptionFundamental;
                      dispatch(
                        setIsAllowedToExpandBussinesDescriptionFundamental(
                          shouldExpand
                        )
                      );
                      setTextEditorIsExpanded((prev: boolean) => !prev);
                    }}
                  >
                    <img src={Expand} alt="expand" />
                  </div>
                </div>
              ) : null}
              <EditableFieldFundamental
                data={data?.businessDesc}
                fieldtype="textEditor"
                fieldName="businessDesc"
                fetchFundamentalData={fetchFundamentalData}
                isTextEditorExpanded={isTextEditorExpanded}
                setTextEditorIsExpanded={setTextEditorIsExpanded}
                onCancel={handleCancelEdit}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      header: "Company History",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md h-full">
            <div className="px-3.5 pt-4 text-lg-semibold text-blue-500 flex items-center justify-between rounded-tl-md rounded-tr-md">
              <span>Company History</span>
              {isAllowedToExpandCompanyHistoryFundamental ? (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    const shouldExpand =
                      !isAllowedToExpandCompanyHistoryFundamental;
                    dispatch(
                      setIsAllowedToExpandCompanyHistoryFundamental(
                        shouldExpand
                      )
                    );
                    setTextEditorIsExpanded((prev: boolean) => !prev);
                  }}
                >
                  <img src={Expand} alt="expand" />
                </div>
              ) : null}
            </div>
            <div
              className={`${
                isTextEditorExpanded
                  ? `fixed bg-white top-[92px] right-[16px] rounded-radius-lg duration-500 transition-[width]  ${
                      isMenuOpen
                        ? "w-[calc(100%-316px)] "
                        : "w-[calc(100%-92px)]"
                    }   z-[999] `
                  : "px-2 pb-2"
              }`}
            >
              <div className={`${isTextEditorExpanded ? "px-2 pb-2" : ""}`}>
                {isTextEditorExpanded ? (
                  <div className="flex justify-between text-left text-lg-medium text-blue-500 pt-4 px-2">
                    <span>Company History</span>

                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        const shouldExpand =
                          !isAllowedToExpandCompanyHistoryFundamental;
                        dispatch(
                          setIsAllowedToExpandCompanyHistoryFundamental(
                            shouldExpand
                          )
                        );
                        setTextEditorIsExpanded((prev: boolean) => !prev);
                      }}
                    >
                      <img src={Expand} alt="expand" />
                    </div>
                  </div>
                ) : null}
                <EditableFieldFundamental
                  data={data?.companyHistory}
                  fieldtype="textEditor"
                  fieldName="companyHistory"
                  fetchFundamentalData={fetchFundamentalData}
                  isTextEditorExpanded={isTextEditorExpanded}
                  setTextEditorIsExpanded={setTextEditorIsExpanded}
                  onCancel={handleCancelEdit}
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Company Basics",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md h-full">
            <div className="px-3.5 pt-4 text-lg-semibold text-blue-500 flex items-center justify-between rounded-tl-md rounded-tr-md">
              <span>Company Basics</span>
              {isAllowedToExpandCompanyBasicsFundamental ? (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    const shouldExpand =
                      !isAllowedToExpandCompanyBasicsFundamental;
                    dispatch(
                      setIsAllowedToExpandCompanyBasicsFundamental(shouldExpand)
                    );
                    setTextEditorIsExpanded((prev: boolean) => !prev);
                  }}
                >
                  <img src={Expand} alt="expand" />
                </div>
              ) : null}
            </div>
            <div
              className={`${
                isTextEditorExpanded
                  ? `fixed bg-white top-[92px] right-[16px] rounded-radius-lg duration-500 transition-[width]  ${
                      isMenuOpen
                        ? "w-[calc(100%-316px)] "
                        : "w-[calc(100%-92px)]"
                    }   z-[999] `
                  : "px-2 pb-2"
              }`}
            >
              <div className={`${isTextEditorExpanded ? "px-2 pb-2" : ""}`}>
                {isTextEditorExpanded ? (
                  <div className="flex justify-between text-left text-lg-medium text-blue-500 pt-4 px-2">
                    <span>Company Basics</span>

                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        const shouldExpand =
                          !isAllowedToExpandCompanyBasicsFundamental;
                        dispatch(
                          setIsAllowedToExpandCompanyBasicsFundamental(
                            shouldExpand
                          )
                        );
                        setTextEditorIsExpanded((prev: boolean) => !prev);
                      }}
                    >
                      <img src={Expand} alt="expand" />
                    </div>
                  </div>
                ) : null}
                <EditableFieldFundamental
                  data={data?.companyBasics}
                  fieldtype="textEditor"
                  fieldName="companyBasics"
                  fetchFundamentalData={fetchFundamentalData}
                  isTextEditorExpanded={isTextEditorExpanded}
                  setTextEditorIsExpanded={setTextEditorIsExpanded}
                  onCancel={handleCancelEdit}
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Drivers / Valuation",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md h-full">
            <div className="px-3.5 pt-4 text-lg-semibold text-blue-500 flex items-center justify-between rounded-tl-md rounded-tr-md">
              <span> Drivers / Valuation</span>
              {isAllowedToExpandDriversFundamental ? (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    const shouldExpand = !isAllowedToExpandDriversFundamental;
                    dispatch(
                      setIsAllowedToExpandDriversFundamental(shouldExpand)
                    );
                    setTextEditorIsExpanded((prev: boolean) => !prev);
                  }}
                >
                  <img src={Expand} alt="expand" />
                </div>
              ) : null}
            </div>
            <div
              className={`${
                isTextEditorExpanded
                  ? `fixed bg-white top-[92px] right-[16px] rounded-radius-lg duration-500 transition-[width]  ${
                      isMenuOpen
                        ? "w-[calc(100%-316px)] "
                        : "w-[calc(100%-92px)]"
                    }   z-[999] `
                  : "px-2 pb-2"
              }`}
            >
              <div className={`${isTextEditorExpanded ? "px-2 pb-2" : ""}`}>
                {isTextEditorExpanded ? (
                  <div className="flex justify-between text-left text-lg-medium text-blue-500 pt-4 px-2">
                    <span> Drivers / Valuation</span>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        const shouldExpand =
                          !isAllowedToExpandDriversFundamental;
                        dispatch(
                          setIsAllowedToExpandDriversFundamental(shouldExpand)
                        );
                        setTextEditorIsExpanded((prev: boolean) => !prev);
                      }}
                    >
                      <img src={Expand} alt="expand" />
                    </div>
                  </div>
                ) : null}
                <EditableFieldFundamental
                  data={data?.driversValuation}
                  fieldtype="textEditor"
                  fieldName="driversValuation"
                  fetchFundamentalData={fetchFundamentalData}
                  isTextEditorExpanded={isTextEditorExpanded}
                  setTextEditorIsExpanded={setTextEditorIsExpanded}
                  onCancel={handleCancelEdit}
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Valuation History",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md h-full">
            <div className="px-3.5 pt-4 text-lg-semibold text-blue-500 flex items-center justify-between rounded-tl-md rounded-tr-md">
              <span> Valuation History</span>
              {isAllowedToExpandValuationHistoryFundamental ? (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    const shouldExpand =
                      !isAllowedToExpandValuationHistoryFundamental;
                    dispatch(
                      setIsAllowedToExpandCompanyHistoryFundamental(
                        shouldExpand
                      )
                    );
                    setTextEditorIsExpanded((prev: boolean) => !prev);
                  }}
                >
                  <img src={Expand} alt="expand" />
                </div>
              ) : null}
            </div>
            <div
              className={`${
                isTextEditorExpanded
                  ? `fixed bg-white top-[92px] right-[16px] rounded-radius-lg duration-500 transition-[width]  ${
                      isMenuOpen
                        ? "w-[calc(100%-316px)] "
                        : "w-[calc(100%-92px)]"
                    }   z-[999] `
                  : "px-2 pb-2"
              }`}
            >
              <div className={`${isTextEditorExpanded ? "px-2 pb-2" : ""}`}>
                {isTextEditorExpanded ? (
                  <div className="flex justify-between text-left text-lg-medium text-blue-500 pt-4 px-2">
                    <span>Valuation History</span>

                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        const shouldExpand =
                          !isAllowedToExpandValuationHistoryFundamental;
                        dispatch(
                          setIsAllowedToExpandValuationHistoryFundamental(
                            shouldExpand
                          )
                        );
                        setTextEditorIsExpanded((prev: boolean) => !prev);
                      }}
                    >
                      <img src={Expand} alt="expand" />
                    </div>
                  </div>
                ) : null}
                <EditableFieldFundamental
                  data={data?.valuationHistory}
                  fieldtype="textEditor"
                  fieldName="valuationHistory"
                  fetchFundamentalData={fetchFundamentalData}
                  isTextEditorExpanded={isTextEditorExpanded}
                  setTextEditorIsExpanded={setTextEditorIsExpanded}
                  onCancel={handleCancelEdit}
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      {loading && <Loader loading={loading} />}
      {isTextEditorExpanded ? (
        <div
          className={` ${
            isMenuOpen ? "left-[284px]" : "left-[60px]"
          } fixed   w-full h-full bg-slate-600 opacity-30  z-[998] duration-500`}
        ></div>
      ) : null}
      <div
        className={`py-4 pr-4 bg-[#F6F7FA] ${
          isMenuOpen ? "pl-[300px]" : "pl-[76px]"
        } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["Fundamentals", `${data?.name || ""}`]}
          icon={fundamentalIcon}
          updateItem={() => navigate(`/update-fundamental/${id}`)}
          addItem={() => navigate("/add-fundamental")}
        />
        <div className="pt-4 rounded-md">
          <div className="flex flex-col md:flex-row gap-4 ">
            <div className="w-full flex flex-col gap-4 h-full">
              {/* Company Information */}
              <CompanyInformation
                fetchFundamentalData={fetchFundamentalData}
                data={data}
                sectorList={sectorList}
              />
              {/* Price Target */}
              <PriceTarget />
              {/* Capitalization Summary */}
              <CapitalizationSummary />
              {/* Valuation Summary */}
              <ValuationSummary />
              {/* Attached Files */}
              <AttachedFiles
                data={data}
                handleFileDownload={handleFileDownload}
              />
              {/* About Fundamental */}
              <AboutFundamental />
            </div>
            <div className="w-full h-full flex flex-col gap-4 dark:bg-primary-dark">
              {/* BUTTONS */}
              <div className="flex gap-3 flex-wrap">
                {tabsContent.map((tab, index) => (
                  <button
                    key={`tab-${index}`}
                    data-testid={`tab-button-${index}`}
                    className={`min-w-[50px] h-[36px] px-2 ${
                      activeTab === index &&
                      "bg-blue-500 text-white  text-xs-medium border-0"
                    } text-blue-500 border border-blue-primary text-xs-medium rounded-md`}
                    onClick={() => {
                      setActiveTab(index);
                      setTextEditorIsExpanded(false);
                      handleCancelEdit();
                    }}
                  >
                    {tab.header}
                  </button>
                ))}
              </div>
              {/* CONTENT */}
              <div
                data-testid="tab-content"
                className="flex flex-col bg-white rounded-md h-full dark:bg-table-dark"
              >
                <div key={activeTab}>{tabsContent[activeTab].content}</div>
              </div>
              <div className={"flex gap-4 h-full flex-col"}>
                {/* Trading Data */}
                <TradingData />
                {/* Key Dates */}
                <KeyDates />
              </div>
              <div className={"flex gap-4 h-full flex-col"}>
                {/* Management */}
                <Management />
                {/* Key Holders */}
                <KeyHolders />
              </div>
              {/* Financial Summary */}
              <FinancialSummary />
            </div>
          </div>
        </div>
        <div className="min-w-full inline-block align-middle mt-4 dark:bg-table-dark">
          {/* NOTES */}
          <Notes data={data} openModal={openModal} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default FundamentalDetails;
