import React, { useState, useRef, useEffect } from "react";

// Api
import { apiConfig } from "../ConfigurationApi/Configuration";
import {
  ContactDomainApi,
  MnaDomainApi,
  RegulatoryDomainApi,
} from "../../openapi";
// Images
import checkmark from "../../assets/images/checkmark.png";
import close from "../../assets/svg/close.svg";
// Components
import Loader from "../Loader";
import InputFieldMna from "./InputFieldMna";
// Utils
import {
  addBrokerClosingDateLocally,
  editClosingDateLocally,
} from "./EditableFieldsUtilities/editableFieldsUtils";
import { sendUpdatedDataToServer } from "./EditableFieldsUtilities/editableFieldsUtils";
import IndividualFieldTextEditor from "./IndividualFieldTextEditor";
import { removeEmptyHTML } from "../../utils";
// Third Party
import ReactQuill from "react-quill";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { handleFormError } from "../../utils/errorHandling";
import { fetchMnaDetails } from "../../features/mnaDetailsSlice";
import { RootState } from "../../store/store";
import { useAppDispatch, useAppSelector } from "../../store/storeHooks";
import EditableFieldPlaceholder from "./EditableFieldPlaceholder";
import { setIsallowedToExpandWhatMatters } from "../../features/textEditorExpand";
import { setIsAllowedToExpandCompanyInformation } from "../../features/textEditorExpand";
import { setIsAllowedToExpandProccesRegulatory } from "../../features/textEditorExpand";

interface Sector {
  label: string;
  value: number;
}

interface EditableFieldMnaProps {
  data?: any;
  fieldName: string;
  fieldtype: "text" | "select" | "date" | "textEditor" | "booleanSelect";
  sectorList?: Sector[];
  dateIndex?: any;
  fullData?: any;
  dateItemValue?: string;
  setDate?: any;
  setFetchMnaDetails?: any;
  setLocalData?: any;
  isRegulatory?: boolean;
  fetchMnaData: () => Promise<void>;
  dataResponse?: any;
  whatMatterId?: any;
  noteStatus?: string;
  isContactEdit?: boolean;
  contactData?: any;
  testId?: string;
  setData?: any;
  setTextEditorIsExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  isTextEditorExpanded?: boolean;
  editorFor?: "mna" | "fundamental" | "regulatory";
  placeholderSelect?: "Category" | "Sector";
}

const EditableFieldMna: React.FC<EditableFieldMnaProps> = ({
  data,
  fieldName,
  fieldtype,
  sectorList = [],
  dateIndex,
  fullData,
  dateItemValue,
  setLocalData,
  fetchMnaData,
  isRegulatory,
  dataResponse,
  whatMatterId,
  noteStatus,
  isContactEdit,
  contactData,
  testId,
  setData,
  setTextEditorIsExpanded,
  isTextEditorExpanded,
  editorFor,
  placeholderSelect,
}) => {
  const [activeRow, setActiveRow] = useState(false);

  const [loading, setLoading] = useState(false);
  const [initialValue, setInitialValue] = useState(data || "");
  const [editorData, setEditorData] = useState({ ref: null, keyName: "" });

  const $description = useRef<ReactQuill | null>(null);

  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };
  const { id } = useParams();
  const safeId = id!;

  const dispatch = useAppDispatch();
  const mnaDetails = useAppSelector(
    (state: RootState) => state.mnaDetails.mnaDetails
  );
  const loadingMnaDetails = useAppSelector(
    (state) => state.mnaDetails.loadingMnaDetails
  );

  useEffect(() => {
    if (isTextEditorExpanded === true) setActiveRow(true);
  }, [isTextEditorExpanded]);

  useEffect(() => {
    const sanitizedData = data === null ? "" : data;
    setInitialValue(sanitizedData);
    formik.setFieldValue("inputValue", sanitizedData);
  }, [data]);

  const formik = useFormik({
    initialValues: {
      inputValue: initialValue ?? "",
    },

    onSubmit: async (values) => {
      if (
        fieldtype === "date" &&
        Array.isArray(fullData?.[fieldName]) &&
        fullData[fieldName][dateIndex]?.isNew
      ) {
        editClosingDateLocally(
          fieldName,
          dateIndex,
          values.inputValue,
          fullData[fieldName][dateIndex].comment,
          setLocalData
        );

        await sendUpdatedDataToServer(
          fieldName,
          fullData,
          safeId,
          setLoading,
          setLocalData,
          values.inputValue
        );
        dispatch(fetchMnaDetails(id!));
        setActiveRow(false);
        return;
      } else if (
        fieldtype === "date" &&
        Array.isArray(mnaDetails?.[fieldName])
      ) {
        editClosingDateLocally(
          fieldName,
          dateIndex,
          values.inputValue,
          fullData[fieldName][dateIndex].comment,
          setLocalData
        );
      }

      if (
        fieldtype === "date" &&
        (fieldName === "dateAnnounced" || fieldName === "dateClosing")
      ) {
        setInitialValue(formatDate(values.inputValue));
      } else {
        setInitialValue(values.inputValue);
      }
      const sanitizedValue = values.inputValue || "";
      let patchValue;

      if (fieldName === "dateAnnounced" || fieldName === "dateClosing") {
        patchValue = sanitizedValue;
      } else {
        patchValue = sanitizedValue;
      }

      if (
        fieldName === "outDates" ||
        fieldName === "brokerClosingDates" ||
        fieldName === "financingDates"
      ) {
        if (fieldtype === "text") {
          editClosingDateLocally(
            fieldName,
            dateIndex,
            fullData?.[fieldName]?.[dateIndex]?.dateEvent,
            values.inputValue,
            setLocalData
          );
          patchValue = {
            edit: {
              [String(mnaDetails?.[fieldName]?.[dateIndex]?.id)]: {
                mnaId: id,
                comment: values.inputValue,
                dateEvent: fullData?.[fieldName]?.[dateIndex]?.dateEvent,
              },
            },
          };
        } else if (fieldtype === "date" && values.inputValue) {
          patchValue = {
            edit: {
              [String(mnaDetails?.[fieldName]?.[dateIndex]?.id)]: {
                mnaId: id,
                dateEvent: values.inputValue,
                comment: fullData?.[fieldName]?.[dateIndex]?.comment,
              },
            },
          };
        }
      } else {
        patchValue = values.inputValue;
      }

      let jsonPatch = [
        {
          op: "replace",
          path: `/${fieldName}`,
          value: patchValue,
        },
      ];

      const jsonPatchForWhatMatters = [
        {
          op: "add",
          path: `/${fieldName}`,
          value: {
            edit: {
              [whatMatterId]: {
                note: patchValue,
              },
            },
          },
        },
      ];

      const jsonPatchForNewWhatMatters = [
        {
          op: "add",
          path: `/${fieldName}`,
          value: {
            add: [
              {
                mnaId: dataResponse?.id,
                note: patchValue,
              },
            ],
          },
        },
      ];

      if (isRegulatory) {
        try {
          const api = new RegulatoryDomainApi(apiConfig());

          if (id) {
            await api.partiallyUpdateRegulatory(String(id), jsonPatch);
          }
          dispatch(fetchMnaDetails(id!));
        } catch (error: any) {
          handleFormError(error);
        } finally {
          setActiveRow(false);
        }
        return;
      }

      if (isContactEdit) {
        const api = new ContactDomainApi(apiConfig());

        const editContactRequestBody: any = {
          name:
            fieldName === "name"
              ? patchValue ?? contactData?.name
              : contactData?.name,
          phone:
            fieldName === "phone"
              ? patchValue ?? contactData?.phone
              : contactData?.phone,
          email:
            fieldName === "email"
              ? patchValue ?? contactData?.email
              : contactData?.email,
          domain: {
            typeId: 1,
            entityId: String(fullData?.id) || "",
          },
        };
        await api.updateContact(
          String(contactData?.id),
          editContactRequestBody
        );
        setActiveRow(false);
        dispatch(fetchMnaDetails(id!));

        return;
      }

      try {
        const api = new MnaDomainApi(apiConfig());
        const payLoadData =
          fieldName === "whatMatters"
            ? noteStatus === "newNote"
              ? jsonPatchForNewWhatMatters
              : jsonPatchForWhatMatters
            : jsonPatch;

        if (id) {
          await api.partiallyUpdateMna(String(id), payLoadData);
        }
        await fetchMnaData();
      } catch (error: any) {
        handleFormError(error);
      } finally {
        setActiveRow(false);
        dispatch(fetchMnaDetails(id!));
      }
    },

    enableReinitialize: true,
  });

  useEffect(() => {
    setInitialValue(data || "");
    formik.setFieldValue("inputValue", data || "");
  }, [data]);
  const handleEdit = () => {
    setActiveRow(true);
    if (
      fieldtype == "textEditor" &&
      fieldName == "whatMatters" &&
      editorFor == "mna"
    ) {
      dispatch(setIsallowedToExpandWhatMatters(true));
    } else if (
      fieldtype == "textEditor" &&
      fieldName == "description" &&
      editorFor == "mna"
    ) {
      dispatch(setIsAllowedToExpandCompanyInformation(true));
    } else if (
      fieldtype == "textEditor" &&
      fieldName == "process" &&
      editorFor == "regulatory"
    ) {
      dispatch(setIsAllowedToExpandProccesRegulatory(true));
    }
  };

  const handleCancel = () => {
    setActiveRow(false);
    if (setTextEditorIsExpanded) setTextEditorIsExpanded(false);
    formik.setFieldValue("inputValue", initialValue);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget === submitButtonRef.current) {
      return;
    }
    handleCancel();
  };

  let selectedSector = formik.values.inputValue;
  if (fieldName === "categoryId") {
    const value = Number(formik.values.inputValue);
    const selectedItem = sectorList.find((item) => item.value === value);
    if (selectedItem) {
      selectedSector = selectedItem.label;
    }
  }
  if (
    (formik.values.inputValue && fieldName === "sectorTarget") ||
    fieldName === "sectorAcquirer"
  ) {
    const index = Number(formik.values.inputValue) - 1;
    if (index >= 0 && index < sectorList.length) {
      selectedSector = sectorList[index].label;
    }
  }

  // Text editor
  const handleEditorRef = (ref: any) => {
    setEditorData(ref);
  };

  const [selectedValue, setSelectedValue] = useState(
    data === true ? "true" : data === false ? "false" : null
  );

  useEffect(() => {
    const value = data === true ? "true" : data === false ? "false" : null;
    setSelectedValue(value);
    if (fieldtype === "booleanSelect") {
      formik.setFieldValue("inputValue", value);
    }
  }, [data]);

  return (
    <div
      data-testid="editable-field"
      className={`flex w-full relative text-xs-normal text-blue-900 ${
        fieldtype === "date"
          ? "justify-end items-center"
          : "justify-between items-start"
      }`}
    >
      {" "}
      {loading && <Loader loading={loading} />}
      <div className="w-full">
        <div onDoubleClick={handleEdit} className="w-full">
          {activeRow ? (
            <form onSubmit={formik.handleSubmit} className="w-full">
              {fieldtype === "select" ? (
                <div className="w-full h-full ">
                  <InputFieldMna
                    label={fieldName}
                    id={fieldName}
                    name="inputValue"
                    type="select"
                    optionList={sectorList}
                    placeholder={placeholderSelect}
                    serverError={""}
                    formik={formik}
                    labelKey="label"
                    valueKey="value"
                    showIcon
                    bgColor={"#fff"}
                    last
                    autoSubmit={true}
                    cancel={handleBlur}
                  />
                </div>
              ) : fieldtype === "booleanSelect" ? (
                <div className="w-full h-full ">
                  <InputFieldMna
                    label={fieldName}
                    id={fieldName}
                    name="inputValue"
                    type="select"
                    optionList={[
                      { value: "true", label: "Yes" },
                      { value: "false", label: "No" },
                    ]}
                    placeholder="Select a value"
                    serverError={""}
                    formik={formik}
                    labelKey="label"
                    valueKey="value"
                    showIcon
                    bgColor={"#fff"}
                    last
                    autoSubmit={true}
                    cancel={handleBlur}
                    setSelectedValue={setSelectedValue}
                    onChange={(value) => {
                      setSelectedValue(value);
                      formik.setFieldValue("inputValue", value);
                    }}
                  />
                </div>
              ) : fieldtype === "date" ? (
                <div className="w-full">
                  <InputFieldMna
                    label="Date"
                    id="inputValue"
                    name="date"
                    type="date"
                    placeholder="date"
                    showIcon
                    bgColor={"#fff"}
                    last={true}
                    formik={formik}
                    cancel={handleCancel}
                  />
                </div>
              ) : fieldtype == "textEditor" ? (
                <IndividualFieldTextEditor
                  formik={formik}
                  id="description"
                  quillRef={$description}
                  name={"inputValue"}
                  isDynamic
                  value={formik.values.inputValue}
                  label="Descrition"
                  onEditorRef={handleEditorRef}
                  isFullScreen={isTextEditorExpanded}
                />
              ) : (
                <div className="">
                  <input
                    type="text"
                    name="inputValue"
                    autoFocus
                    value={formik.values.inputValue}
                    onChange={formik.handleChange}
                    className="rounded-sm px-2 py-1 my-1  box-border border border-blue-500 focus:outline-none w-full h-full"
                    onBlur={handleBlur}
                  />
                </div>
              )}
              {fieldtype === "textEditor" ? (
                <div
                  className={`flex justify-end gap-3 mt-2 mb-2 ${
                    isTextEditorExpanded ? "mb-4" : ""
                  }  mr-2`}
                >
                  <button
                    onClick={() => {
                      handleCancel();
                      if (fieldName == "whatMatters")
                        dispatch(setIsallowedToExpandWhatMatters(false));
                      if (fieldName == "description")
                        dispatch(setIsAllowedToExpandCompanyInformation(false));
                      if (fieldName == "process")
                        dispatch(setIsAllowedToExpandProccesRegulatory(false));
                    }}
                    className="font-medium"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={() => {
                      setTextEditorIsExpanded && setTextEditorIsExpanded(false);
                      dispatch(setIsallowedToExpandWhatMatters(false));
                      dispatch(setIsAllowedToExpandCompanyInformation(false));
                      dispatch(setIsAllowedToExpandProccesRegulatory(false));
                    }}
                    className="font-medium bg-blue-primary text-body rounded-md py-2 px-4"
                  >
                    Save
                  </button>
                </div>
              ) : (
                <div
                  className={`absolute right-[0px] flex space-x-[3px] shadow-2xl ${
                    fieldtype === "select" || fieldtype === "booleanSelect"
                      ? "hidden"
                      : "top-8"
                  }`}
                >
                  {" "}
                  {fieldtype !== "date" && (
                    <>
                      <button
                        type="submit"
                        ref={submitButtonRef}
                        className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-lg transition-shadow duration-200 border-1 border-primary-light shadow-shadow-md"
                      >
                        <img
                          className="w-3 h-3"
                          src={checkmark}
                          alt="checkmark"
                        />
                      </button>

                      <button
                        type="button"
                        onClick={handleCancel}
                        className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-lg transition-shadow duration-50 border-1 border-primary-light shadow-shadow-md"
                      >
                        <img src={close} className="w-3 h-3" alt="close" />
                      </button>
                    </>
                  )}
                </div>
              )}
            </form>
          ) : (
            <div
              className={`  rounded-sm cursor-text flex flex-col align-center break-words whitespace-normal `}
            >
              {fieldName !== "requiredToLitigate" ? (
                initialValue ? (
                  <>
                    <span
                      className={`hover:bg-button-dark hover:bg-opacity-15 text-xs-normal text-blue-900 rounded-md p-2 
            ${
              fieldtype === "text" &&
              (fieldName === "brokerClosingDates" ||
                fieldName === "financingDates" ||
                fieldName === "outDates")
                ? "self-start"
                : fieldName === "outDates"
                ? "self-start"
                : fieldtype === "select"
                ? ""
                : fieldtype === "text"
                ? " self-start"
                : fieldtype === "date" &&
                  (fieldName === "brokerClosingDates" ||
                    fieldName === "financingDates")
                ? "self-start"
                : ""
            }`}
                    >
                      {fieldtype === "select" ? (
                        selectedSector
                      ) : fieldtype === "date" ? (
                        formik.values.inputValue
                      ) : fieldtype === "textEditor" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              removeEmptyHTML(formik.values.inputValue) || "",
                          }}
                        />
                      ) : (
                        <span className="break-all">{initialValue}</span>
                      )}
                    </span>
                  </>
                ) : (
                  <div
                    data-testid={testId}
                    className={`${
                      fieldtype === "textEditor" && isTextEditorExpanded
                        ? "hidden"
                        : ""
                    } bg-opacity-10 w-full rounded-md px-2 hover:bg-table-dark hover:bg-opacity-10`}
                  >
                    <p className="py-2">-</p>
                  </div>
                )
              ) : (
                <span className="pl-2">
                  <EditableFieldPlaceholder
                    content={
                      selectedValue === "true"
                        ? "Yes"
                        : selectedValue === "false"
                        ? "No"
                        : "-"
                    }
                  />
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditableFieldMna;
