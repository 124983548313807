import { useQuery } from "@tanstack/react-query";
import { Configuration, LinkCategoryDomainApi } from "../openapi";
import { expiredTokenValidation } from "../api/expiredTokenValidation";

const fetchLinksCategories = async () => {
  const configuration = new Configuration({
    basePath: process.env.REACT_APP_API_URL,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  });

  try {
    const linkCategoryApi = new LinkCategoryDomainApi(configuration);
    const response = await linkCategoryApi.getLinkCategories();
    return response.data.content || [];
  } catch (err: any) {
    console.error("Error fetching Link Categories:", err);
    expiredTokenValidation(err);
    throw new Error("Failed to load Link Categories");
  }
};

export const useLinksCategories = () => {
  const {
    data: linksCategories = [],
    isLoading: loadingLinksCategories,
    error: errorLinksCategories,
    refetch,
  } = useQuery({
    queryKey: ["linksCategories"],
    queryFn: fetchLinksCategories,
    staleTime: 10 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return {
    linksCategories,
    loadingLinksCategories,
    errorLinksCategories,
    refetch,
  };
};
