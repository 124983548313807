import { useQuery } from "@tanstack/react-query";
import { Configuration, MnaCategoryDomainApi } from "../openapi";

const fetchMnaCategories = async () => {
  const configuration = new Configuration({
    basePath: process.env.REACT_APP_API_URL,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  });

  try {
    const mnaCategoryApi = new MnaCategoryDomainApi(configuration);
    const response = await mnaCategoryApi.getMnaCategories("", 0);
    return response.data.content!;
  } catch (error) {
    console.error("Error fetching MNA Categories:", error);
    throw error;
  }
};

export const useMnaCategory = () => {
  const {
    data: mnaCategory = [],
    isLoading: loadingMnaCategory,
    error: errorMnaCategory,
  } = useQuery({
    queryKey: ["mnaCategories"],
    queryFn: fetchMnaCategories,
    staleTime: 10 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return { mnaCategory, loadingMnaCategory, errorMnaCategory };
};
