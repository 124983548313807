// React
import React, { useEffect, useState } from "react";
// Third party
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  Column,
} from "react-table";
// API
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
import { axiosInstance } from "../ConfigurationApi/Configuration";
// Images
import Arrow from "../../assets/svg/arrow-down-file-list-table.svg";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PaginationActionType } from "../../types/pagination-types";
// OpenAPI
import { AlertReadDomainApiAxiosParamCreator } from "../../openapi";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  nextAlertsPage,
  previousAlertsPage,
  setAlertPageIndex,
} from "../../features/alertsPagination";
import { Pagination } from "../Pagination/Pagination";
import Elipse from "../../assets/svg/alerts/Ellipse.svg";
import DotsHorizontal from "../../assets/svg/alerts/dots-horizontal.svg";
import ArrowBeforeAfter from "../../assets/svg/alerts/ArrowBeforeAfter.svg";
import ExpandArrow from "../../assets/svg/alerts/ChevronDown.svg";
import AlertDiffContainer from "../../pages/AlertsList/AlertDiffContainer";
import { RootState } from "../../store/store";
import { useMnaPreload } from "../../hooks/useMnaPreload";
import { getFundamentalById } from "../../pages/MnaAddEditMiniForm/services/MiniFormServices";
import { fetchRegulatoryData } from "../../pages/RegulatoryDetails/services/regulatoryService";
import { fetchNoteByIdApi } from "../../pages/NoteAddEdit/services/noteServices";
import { fetchMnaData } from "../../pages/MnaDetails/services/mnaService";

interface TableProps {
  columns: Column[];
  additionalDataKey: string;
  openModal?: ((value: any) => void) | undefined;
  caption?: string;
  search?: string;
  loading?: boolean;
  fetchData: (
    pageIndex: number,
    pageSize: number
    // sortBy: any,
    // globalFilter: string
  ) => Promise<any>;
  isMenuOpen: boolean;
  typeForPagination: PaginationActionType;
  alertData: Array<{
    id: number;
    name: string;
    color?: string;
    icon?: any;
  }>;
}

const Table: React.FC<TableProps> = ({
  columns,
  fetchData,
  search,
  isMenuOpen,
  typeForPagination,
  alertData,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [mnaIds, setMnaIds] = useState<string[]>([]);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [totalElement, setTotalElement] = useState(0);
  const [expandedDiffList, setExpandedDiffList] = useState<string[]>([]);
  const [inputPage, setInputPage] = useState<any>("");
  const navigate = useNavigate();
  const location = useLocation();
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    gotoPage,
    pageCount,
    setPageSize,
    rows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualSortBy: true,
      manualPagination: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
    } as any,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  const { globalFilter, pageSize, sortBy } = state;
  useEffect(() => {
    dispatch(setAlertPageIndex(0));
    setPageSize(10);
  }, [location, setPageSize]);

  // useEffect(() => {
  //   gotoPage(0);
  //   setPageSize(10);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search]);

  const paginationStateMap: Record<PaginationActionType, keyof RootState> = {
    setErrorsListPageIndex: "errorsListPagination",
    setFilesListPageIndex: "filesListPagination",
    setFundamentalListPageIndex: "fundamentalListPagination",
    setMnaListPageIndex: "mnaListPagination",
    setNotesForMnaPageIndex: "notesForMnaPagination",
    setNotesListPageIndex: "notesListPagination",
    setRegulatoryListPageIndex: "regulatoryListPagination",
    setNotesForFundamentalPageIndex: "notesForFundamental",
    setAlertPageIndex: "alertsPagination",
  };

  const selectedPaginationSlice = paginationStateMap[typeForPagination!];

  const pageIndex = useSelector((state: RootState) => {
    const paginationState = state[selectedPaginationSlice];
    if (paginationState && "pageIndex" in paginationState) {
      return paginationState.pageIndex;
    }
    return 0;
  });

  useEffect(() => {
    setExpandedDiffList([]);
  }, [pageIndex]);

  const { getMnaCached } = useMnaPreload(mnaIds);

  const [alertTitles, setAlertTitles] = useState<Record<string, JSX.Element>>(
    {}
  );

  useEffect(() => {
    const fetchTitles = async () => {
      for (let alert of data) {
        await fetchAlertTitle(alert);
      }
    };

    fetchTitles();
  }, [data]);

  const fetchAlertTitle = async (alert: any) => {
    if (alertTitles[alert.entityId]) {
      return alertTitles[alert.entityId];
    }

    let title: any;

    if (alert.domainTypeId == "1") {
      const mna =
        getMnaCached(alert.entityId) || (await fetchMnaData(alert.entityId));
      title = (
        <Link
          className="text-blue-900 hover:text-blue-500 text-sm-semibold"
          to={`/mna/${alert.entityId}`}
        >
          {mna.name}
        </Link>
      );
    } else if (alert.domainTypeId == "2") {
      const note = await fetchNoteByIdApi(alert.entityId);
      title = (
        <Link
          className="text-blue-900 hover:text-blue-500 text-sm-semibold"
          to={`/notes/${alert.entityId}`}
        >
          {note.data.title}
        </Link>
      );
    } else if (alert.domainTypeId == "9") {
      const fundamental = await getFundamentalById(alert.entityId);
      title = (
        <Link
          className="text-blue-900 hover:text-blue-500 text-sm-semibold"
          to={`/fundamental/${alert.entityId}`}
        >
          {fundamental.name}
        </Link>
      );
    } else if (alert.domainTypeId == "11") {
      const regulatory = await fetchRegulatoryData(alert.entityId);
      title = (
        <Link
          className="text-blue-900 hover:text-blue-500 text-sm-semibold"
          to={`/regulatory/${alert.entityId}`}
        >
          {regulatory.name}
        </Link>
      );
    }

    setAlertTitles((prevTitles) => ({
      ...prevTitles,
      [alert.entityId]: title,
    }));

    return title;
  };

  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      try {
        const result = await fetchData(pageIndex, pageSize);
        const newMnaIds = new Set<string>();
        result.content.forEach((alert: any) => {
          if (alert.domainTypeId === 1) {
            newMnaIds.add(alert.entityId);
          }
          if (alert.diffList) {
            alert.diffList.forEach((diff: any) => {
              if (diff.name === "mna") {
                if (diff.before?.cdoId) newMnaIds.add(diff.before.cdoId);
                if (diff.after?.cdoId) newMnaIds.add(diff.after.cdoId);
              }
            });
          }
        });

        setData(result.content);
        setMnaIds(Array.from(newMnaIds));
        setControlledPageCount(Math.ceil(result.page.totalPages));
        setTotalElement(Math.ceil(result.page.totalElements));
      } catch (error) {
        expiredTokenValidation(error);
      }
    };

    fetchDataAndUpdateState();
  }, [fetchData, pageIndex, pageSize, sortBy, globalFilter, search]);

  const dispatch = useDispatch();

  const handleNextPage = () => {
    dispatch(nextAlertsPage());
  };
  const handlePreviousPage = () => {
    dispatch(previousAlertsPage());
  };

  const handleSetPage = (index: number) => {
    dispatch(setAlertPageIndex(index));
  };

  const toggleExpand = (alertId: string) => {
    setExpandedDiffList((prev) =>
      prev.includes(alertId)
        ? prev.filter((id) => id !== alertId)
        : [...prev, alertId]
    );
  };

  const markAsRead = async (alertId: string) => {
    try {
      const paramCreator = AlertReadDomainApiAxiosParamCreator();
      const createAlertReadArgs = await paramCreator.createAlertRead({
        alertId,
      });
      const response = await axiosInstance({
        ...createAlertReadArgs.options,
        url: createAlertReadArgs.url,
      });

      return response.data;
    } catch (error) {
      console.error("Error marking alert as read:", error);
      throw error;
    }
  };
  return (
    <React.Fragment>
      <table
        {...getTableProps()}
        className="border-separate border-spacing-y-3 w-full"
      >
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            const alert = row.original;
            const alertDataItem = alertData.find(
              (a: any) => a.id === alert.domainTypeId
            );

            if (!alert.diffList || alert.diffList.length === 0) {
              return null;
            }
            const isExpanded = expandedDiffList.includes(`${row.id}`);

            return (
              <tr className="" {...row.getRowProps()} key={row.id}>
                <td colSpan={columns.length} className="break-words break-all">
                  <div
                    className="bg-white px-4 pb-4 -ml-4 border-1  rounded-xl"
                    style={{
                      borderColor: alertDataItem?.color || "transparent",
                    }}
                  >
                    {/* Alert Header */}
                    <div className="py-4 flex justify-between items-center">
                      <div className="flex items-center">
                        <img
                          src={Elipse}
                          alt="status"
                          className="mr-2 w-2 h-2"
                        />
                        {alertDataItem?.icon && (
                          <img
                            className="p-1 rounded-full w-5 h-5 mr-2"
                            style={{ backgroundColor: alertDataItem.color }}
                            src={alertDataItem.icon}
                            alt={`${alertDataItem.name} icon`}
                          />
                        )}
                        <p className="text-sm font-semibold">
                          {alertTitles[alert.entityId] ||
                            `${alertDataItem?.name || "Unknown"} Alert`}
                        </p>
                      </div>
                      <button className="p-1 hover:bg-gray-100 rounded">
                        <img
                          src={DotsHorizontal}
                          alt="actions"
                          className="w-5 h-5"
                        />
                      </button>
                    </div>

                    {/* Alert Content */}
                    {alert.diffList?.map((diffItem: any, diffIndex: number) => {
                      if (diffIndex === 0 || isExpanded) {
                        return (
                          <div key={diffIndex}>
                            <AlertDiffContainer
                              alert={alert}
                              diffItem={diffItem}
                              diffIndex={diffIndex}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* Expand/Collapse */}
                    {alert.diffList?.length > 1 && (
                      <div className="flex justify-center mt-2">
                        <button
                          onClick={() => toggleExpand(row.id)}
                          className="p-2 hover:bg-gray-100 rounded-full"
                        >
                          <img
                            src={ExpandArrow}
                            alt="expand"
                            className={`w-5 h-5 transition-transform ${
                              isExpanded ? "rotate-180" : ""
                            }`}
                          />
                        </button>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination */}
      <div
        className={`fixed bottom-0 -ml-8 ${
          isMenuOpen ? "w-[calc(100%-278px)]" : "w-[calc(100%-62px)]"
        }  p-4 flex flex-col md:flex-row justify-between bg-grey-50 duration-500`}
        data-testid="pagination-main"
      >
        <div className="mb-4 md:mb-0 md:flex md:justify-between md:items-center">
          <div className="mb-2 md:mb-0">
            <span className="text-primary text-sm border-r border-primary border-secondary pr-[8px]">
              Showing{" "}
              <span className="font-medium">
                {" "}
                {data.length !== 0 && pageIndex * pageSize + 1}
              </span>{" "}
              {data.length !== 0 && "-"}{" "}
              {pageIndex === pageCount - 1 ? (
                <span className="font-semibold">
                  {Math.min((pageIndex + 1) * pageSize, totalElement)}
                </span>
              ) : (
                <span className="font-semibold">
                  {" "}
                  {Math.min((pageIndex + 1) * pageSize, totalElement)}{" "}
                </span>
              )}{" "}
              of <span className="font-semibold"> {totalElement}</span> Entries
            </span>
          </div>
          <div className="flex items-center gap-1">
            <div className="text-primary text-sm font-normal mt-[2px] ml-[8px]">
              Show Entries
            </div>
            <div className="relative">
              <select
                style={{
                  appearance: "none",
                  backgroundColor: "white",
                  paddingRight: "35px",
                  fontSize: "14px",
                  width: "74px",
                  height: "34px",
                  outline: "none",
                  border: "1px solid #E5E5EA",
                  borderRadius: "8px",
                  transition: "border-color 0.3s ease-in-out",
                  backgroundImage: `url(${Arrow})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "calc(100% - 15px) center",
                  backgroundSize: "16px 16px",
                }}
                value={pageSize}
                onChange={(e) => {
                  const newPageSize = Number(e.target.value);
                  let currentElementIndex = pageIndex * pageSize;
                  let newPageIndex = Math.floor(
                    currentElementIndex / newPageSize
                  );
                  handleSetPage(newPageIndex);
                  setPageSize(newPageSize);
                }}
                className="dark:text-white dark:placeholder:text-white dark:bg-b-secondary-dark dark:border-white border border-[#E5E5EA] rounded-[8px] px-[20px] py-[5px] text-primary appearance-none outline-none cursor-pointer w-[84px] h-[34px]"
              >
                {[10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex items-center" data-testid="pagination-buttons">
          <Pagination
            pageCount={pageCount}
            pageIndex={pageIndex}
            typeForPagination={typeForPagination!}
          />
        </div>
        <div className="text-secondary flex items-center mt-4 md:mt-0">
          <div className="flex items-center space-x-4">
            <button
              onClick={handlePreviousPage}
              disabled={pageIndex == 0}
              data-testid="previous-button"
              className={`flex items-center px-4 py-1 rounded-md text-[#181825] text-[14px] border border-transparent 
                hover:bg-[#EDF6FF] hover:text-[#3B97FF] hover:border-[#3B97FF] disabled:opacity-50 cursor-pointer`}
            >
              <span className="mr-1">←</span> Previous
            </button>
            <button
              onClick={handleNextPage}
              disabled={pageIndex >= pageCount - 1}
              data-testid="next-button"
              className={`flex items-center px-4 py-1 rounded-md text-[#181825] text-[14px] border border-transparent 
hover:bg-[#EDF6FF] hover:text-[#3B97FF] hover:border-[#3B97FF] disabled:opacity-50 cursor-pointer -mb-[1px]`}
            >
              Next <span className="ml-1">→</span>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Table;
