// React
import React, { useState, useRef, useEffect } from "react";
// Third party
import moment from "moment/moment";
import { useParams } from "react-router-dom";
// Components
import ConditionsDeleteModal from "../Modal/DeleteModalMNAConditions";
import Tooltip from "../Tooltip/Tooltip";
import ConditionsAddCancelBtn from "./ConditionsAddCancelBtn";
import ReactDatePicker from "react-datepicker";
// Utils
import { formatAndValidateDate } from "../../utils";
// Types
import { IConditions } from "../../types";
// Images
import PlusIcon from "../../assets/svg/plus-icon-add-button.svg";
import DeleteIcon from "../../assets/svg/deleteIconConditions.svg";

// Hooks
import { useConditionCategories } from "../../hooks/useConditionCategories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";

// Redux
import {
  AddConditionsDomainApi,
  UpdateconditionsDomainApi,
  deleteConditionById,
} from "../../pages/MnaDetails/services/conditionsService";
import { handleFormError } from "../../utils/errorHandling";
import CustomSelect from "../CustomSelect/CustomSelect";
import CategorySelect from "../CustomSelect/CustomSelect";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
// Types

interface ConditionsMnaTableProps {
  conditionsData: IConditions[];
  setLoading: (loading: boolean) => void;
  setConditionsData: any;
  setFetchRequired: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConditionsMnaTable: React.FC<ConditionsMnaTableProps> = ({
  conditionsData,
  setLoading,
  setConditionsData,
  setFetchRequired,
}) => {
  const { id } = useParams();
  const [editingRowId, setEditingRowId] = useState<string | undefined>("");
  const [editingRowData, setEditingRowData] = useState<IConditions | null>(
    null
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isAddingCondition, setIsAddingCondition] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const [isCalendarFieldOpen, setCalendarFieldOpen] = useState(false);

  const [conditionToDelete, setConditionToDelete] = useState<
    string | undefined | any
  >("");
  const [newCondition, setNewCondition] = useState({
    version: 0,
    mnaId: id,
    conditionCategoryId: 0,
    categoryOtherComment: "",
    statusId: 2,
    filed: null,
    expectedConfirmed: null,
    commentary: "",
    expectedConfirmedFlag: false,
  });

  const [isHovered, setIsHovered] = useState(false);
  const { conditionCategories } = useConditionCategories();

  const handleAddConditionToggle = () => {
    if (isAddingCondition) {
      saveNewCondition();
    } else {
      setIsAddingCondition(true);
    }
  };

  const toggleVisibility = (id: string, isVisible: boolean) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = isVisible ? "flex" : "none";
      element.style.justifyContent = isVisible ? "center" : "";
    }
  };

  const startEditing = (row: IConditions) => {
    setEditingRowId(row.id);
    setEditingRowData({ ...row });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === "conditionCategoryId" || name === "statusId") {
      setNewCondition((prev) => ({
        ...prev,
        [name]:
          name === "conditionCategoryId" || name === "statusId"
            ? Number(value)
            : value,
      }));
    } else {
      setNewCondition((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleFieldDateChange = (
    date: Date | null,
    event?: React.SyntheticEvent
  ) => {
    if (date) {
      date.setHours(12, 0, 0, 0);
    }
    setNewCondition((prev: any) => ({ ...prev, filed: date }));
  };

  const handleExpectedDateChange = (
    date: Date | null,
    event?: React.SyntheticEvent
  ) => {
    if (date) {
      date.setHours(12, 0, 0, 0);
    }
    setNewCondition((prev: any) => ({ ...prev, expectedConfirmed: date }));
  };

  const handleFieldEditeDate = (date: Date | null) => {
    if (date) {
      date.setHours(12, 0, 0, 0);
    }
    setEditingRowData((prev) => (prev ? { ...prev, filed: date } : null));
  };

  const handleExpectedEditeDate = (date: Date | null) => {
    if (date) {
      date.setHours(12, 0, 0, 0);
    }
    setEditingRowData((prev) =>
      prev ? { ...prev, expectedConfirmed: date } : null
    );
  };

  const handleToggleChangeAdd = () => {
    // @ts-ignore
    setNewCondition((prev) => ({
      ...prev,
      expectedConfirmedFlag: !prev?.expectedConfirmedFlag,
    }));
  };

  const handleToggleChangeEdit = () => {
    // @ts-ignore
    setEditingRowData((prev) => ({
      ...prev,
      expectedConfirmedFlag: !prev?.expectedConfirmedFlag,
    }));
  };

  const saveEditedRow = async () => {
    if (!editingRowData) return;
    setLoading(true);
    try {
      const body = {
        version: editingRowData.version,
        mnaId: editingRowData.mnaId,
        conditionCategoryId: editingRowData.conditionCategoryId,
        categoryOtherComment: editingRowData.categoryOtherComment,
        statusId:
          typeof editingRowData?.statusId === "number"
            ? editingRowData?.statusId
            : editingRowData?.statusId === "Complete"
            ? 1
            : 2,
        expectedConfirmed: editingRowData.expectedConfirmed,
        expectedConfirmedFlag:
          editingRowData.expectedConfirmedFlag !== null
            ? editingRowData.expectedConfirmedFlag
            : false,
        commentary: editingRowData.commentary,
        filed: editingRowData.filed,
      };
      await UpdateconditionsDomainApi(editingRowData.id, body);
      setConditionsData((prevData: IConditions[]) => {
        return prevData.map((condition: IConditions) => {
          return condition.id === editingRowData.id
            ? {
                ...condition,
                ...editingRowData,
                conditionCategoryId: editingRowData.conditionCategoryId,
                category: editingRowData.category,
              }
            : condition;
        });
      });
      setEditingRowId("");
      setEditingRowData(null);
    } catch (error) {
      console.error("Error updating condition:", error);
      alert("Failed to update condition");
    } finally {
      setLoading(false);
    }
  };

  const saveNewCondition = async () => {
    setLoading(true);
    try {
      await AddConditionsDomainApi(newCondition);
      const savedCondition = newCondition;
      setConditionsData((prev: IConditions[]) => {
        return [...prev, savedCondition];
      });
      setIsAddingCondition(false);
      setNewCondition({
        version: 0,
        mnaId: id,
        conditionCategoryId: 0,
        categoryOtherComment: "",
        statusId: 2,
        filed: null,
        expectedConfirmed: null,
        commentary: "",
        expectedConfirmedFlag: false,
      });
      setFetchRequired((prev: any) => !prev);
    } catch (error) {
      handleFormError(error);
    } finally {
      setLoading(false);
    }
  };

  const openDeleteModal = (conditionId: string | undefined) => {
    setConditionToDelete(conditionId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setConditionToDelete("");
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCondition = async () => {
    setLoading(true);
    try {
      await deleteConditionById(conditionToDelete);
      setConditionToDelete(!conditionToDelete);
      setConditionsData((prev: any) =>
        prev.filter((item: any) => item.id !== conditionToDelete)
      );
    } catch (error) {
      console.error("Error deleting condition:", error);
      alert("Failed to delete condition");
    } finally {
      closeDeleteModal();
      setLoading(false);
    }
  };

  const sortedData = conditionsData.sort((a: any, b: any) => {
    if (!sortField) return 0;

    if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
    return 0;
  });

  const toggleRef = useRef<HTMLDivElement>(null);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  const editingRowRef = useRef<HTMLDivElement>(null);
  const addingRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        editingRowRef.current &&
        !editingRowRef.current.contains(event.target as Node) &&
        !confirmButtonRef.current?.contains(event.target as Node)
      ) {
        setEditingRowId("");
        setEditingRowData(null);
      }

      if (
        addingRowRef.current &&
        !addingRowRef.current.contains(event.target as Node) &&
        !confirmButtonRef.current?.contains(event.target as Node)
      ) {
        setIsAddingCondition(false);
        setNewCondition({
          version: 0,
          mnaId: id,
          conditionCategoryId: 0,
          categoryOtherComment: "",
          statusId: 2,
          filed: null,
          expectedConfirmed: null,
          commentary: "",
          expectedConfirmedFlag: false,
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [id]);

  const handleSort = (field: any) => {
    if (sortField === field) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  return (
    <div className=" mt-4">
      <div className="min-w-full inline-block align-middle ">
        {/* CONDITIONS */}
        <div className="border-1 border-gray-200  bg-white pt-4 rounded-[8px]  ">
          <div className="flex items-center justify-between px-4">
            <div className="text-blue-500 text-lg-medium ">Conditions</div>
            <button
              ref={confirmButtonRef}
              type="button"
              onClick={handleAddConditionToggle}
              className="flex items-center justify-between text-blue-500 border-1 border-blue-500 rounded-radius-lg  text-center text-xs-normal px-3 py-2"
              style={{
                visibility:
                  !isAddingCondition && !editingRowId ? "visible" : "hidden",
              }}
            >
              <img
                src={PlusIcon}
                alt="Add"
                className="mr-[8px] w-[14px] h-[14px]"
              />
              Add New
            </button>
            {/* )} */}
          </div>
          <div className=" mt-4 ">
            {/* Header */}
            <div className="grid grid-cols-7 bg-grey-50 items-center gap-4 px-4 grid-flow-col auto-cols-auto">
              <div
                className="pt-[12px] pb-[12px]   text-start flex items-center gap-1 cursor-pointer text-xs-normal col-span-1 "
                onClick={() => handleSort("conditionCategoryId")}
              >
                <span>Name</span>
                <span>
                  {sortField === "conditionCategoryId" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </span>
              </div>

              <div
                className="pt-[12px] pb-[12px]   text-start flex items-center gap-1 cursor-pointer text-xs-normal col-span-3"
                onClick={() => handleSort("commentary")}
              >
                <span>Commentary</span>
                <span>
                  {sortField === "commentary" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </span>
              </div>

              <div
                className="pt-[12px] pb-[12px]  text-start flex items-center gap-1 cursor-pointer text-xs-normal col-span-1"
                onClick={() => handleSort("filed")}
              >
                <span>Filed/File-By</span>
                <span>
                  {sortField === "filed" && (sortOrder === "asc" ? "↑" : "↓")}
                </span>
              </div>

              <div
                className="pt-[12px] pb-[12px]  text-start flex items-center gap-1 cursor-pointer text-xs-normal col-span-1"
                onClick={() => handleSort("expectedConfirmed")}
              >
                <span>Expected/Confirmed</span>
                <span>
                  {sortField === "expectedConfirmed" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </span>
              </div>

              <div
                className="pt-[12px] pb-[12px]  text-start flex items-center gap-1 cursor-pointer text-xs-normal col-span-1"
                onClick={() => handleSort("statusId")}
              >
                <span>Status</span>
                <span>
                  {sortField === "statusId" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </span>
              </div>
              <div className="w-[30px]"></div>
            </div>

            {/* Body */}
            <div>
              {isDeleteModalOpen && (
                <ConditionsDeleteModal
                  title="Are you sure you want to delete this condition?"
                  onClose={closeDeleteModal}
                  onConfirm={handleDeleteCondition}
                />
              )}

              {isAddingCondition && (
                <div
                  className="relative"
                  onMouseOver={() => {
                    toggleVisibility(`edit${1}`, true);
                  }}
                  onMouseLeave={() => {
                    toggleVisibility(`edit${1}`, false);
                  }}
                >
                  <div
                    ref={addingRowRef}
                    className={`grid grid-cols-7  items-center gap-4 px-4 grid-flow-col auto-cols-auto relative   ${
                      sortedData.length == 0 ? "border-b-0" : "border-b-1"
                    } pb-[5px] h-fit`}
                  >
                    <div>
                      <CategorySelect
                        name="conditionCategoryId"
                        value={newCondition.conditionCategoryId}
                        onChange={handleInputChange}
                        options={conditionCategories}
                        className="col-span-1"
                        placeholder="Select Category"
                      />
                    </div>
                    <div className="col-span-3">
                      <input
                        type="text"
                        name="commentary"
                        value={newCondition.commentary}
                        onChange={handleInputChange}
                        autoComplete="off"
                        className="w-full  mt-[6px] px-2 py-1  border-1 border-blue-primary rounded-[4px] focus:outline-none text-xs-normal"
                      />
                    </div>
                    {/* setCalendarFieldOpen */}
                    <div className="col-span-1 ">
                      <CustomDatePicker
                        selected={newCondition.filed}
                        handleDateChage={handleFieldDateChange}
                        name="field"
                        id="date"
                        setNewCondition={setNewCondition}
                      />
                    </div>

                    <div className="col-span-1 flex items-center">
                      <CustomDatePicker
                        selected={newCondition.expectedConfirmed}
                        handleDateChage={handleExpectedDateChange}
                        name="expectedConfirmed"
                        id="date"
                        setNewCondition={setNewCondition}
                      />

                      <div
                        ref={toggleRef}
                        className="relative mt-1 ml-4"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <div
                          onClick={handleToggleChangeAdd}
                          className="cursor-pointer flex justify-center items-center gap-2 w-full"
                        >
                          {newCondition.expectedConfirmedFlag ? (
                            <FontAwesomeIcon
                              icon={faToggleOn}
                              className="text-blue-primary text-[20px] "
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faToggleOff}
                              className="text-blue-primary text-[20px]"
                            />
                          )}
                        </div>
                        {isHovered && (
                          <Tooltip
                            targetRef={toggleRef}
                            text={
                              editingRowData?.expectedConfirmedFlag
                                ? "When toggled, Expected/Confirmed date is marked as confirmed"
                                : "When not toggled, Expected/Confirmed date remains as expected."
                            }
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-span-1">
                      <CustomSelect
                        name="statusId"
                        placeholder="Select status"
                        value={newCondition.statusId}
                        onChange={handleInputChange}
                        options={[
                          { id: 2, name: "Pending" },
                          { id: 1, name: "Complete" },
                        ]}
                      />
                    </div>
                    <div className="w-[30px]">
                      <div className=" cursor-pointer hidden" id="edit1">
                        <img
                          src={DeleteIcon}
                          alt="delete"
                          onClick={() => setIsAddingCondition(false)}
                        />
                      </div>
                    </div>
                  </div>
                  {isAddingCondition && (
                    <div className="absolute top-[34px] right-[100px]">
                      <ConditionsAddCancelBtn
                        editingRowId={editingRowId}
                        saveEditedRow={saveEditedRow}
                        handleAddConditionToggle={handleAddConditionToggle}
                        setIsAddingCondition={setIsAddingCondition}
                        confirmButtonRef={confirmButtonRef}
                      />
                    </div>
                  )}
                </div>
              )}
              {sortedData?.map((item: IConditions, index: number) =>
                editingRowId === item.id ? (
                  <div
                    className="relative"
                    onMouseOver={() => {
                      toggleVisibility(`edit${item.id}`, true);
                    }}
                    onMouseLeave={() => {
                      toggleVisibility(`edit${item.id}`, false);
                    }}
                  >
                    <div
                      key={`${index}conditions`}
                      ref={editingRowRef}
                      className="grid grid-cols-7  items-center gap-4 px-4 grid-flow-col auto-cols-auto relative  border-b-1 last:border-b-0 pb-[5px] h-fit"
                    >
                      <div>
                        <CategorySelect
                          name="conditionCategoryId"
                          value={editingRowData?.conditionCategoryId || ""}
                          onChange={(e) =>
                            setEditingRowData((prev) =>
                              prev
                                ? {
                                    ...prev,
                                    conditionCategoryId: Number(e.target.value),
                                  }
                                : null
                            )
                          }
                          options={conditionCategories}
                          className="col-span-1"
                          placeholder="Select Category"
                        />
                      </div>
                      <div className="col-span-3">
                        <input
                          type="text"
                          name="commentary"
                          value={editingRowData?.commentary || ""}
                          onChange={(e) =>
                            setEditingRowData((prev) =>
                              prev
                                ? { ...prev, commentary: e.target.value }
                                : null
                            )
                          }
                          autoComplete="off"
                          className="w-full  mt-[6px] px-2 py-1  border-1 border-blue-primary rounded-[4px] focus:outline-none text-xs-normal"
                        />
                      </div>
                      <div className="col-span-1 ">
                        <CustomDatePicker
                          selected={editingRowData?.filed || ""}
                          handleDateChage={handleFieldEditeDate}
                          name="field"
                          id="date"
                          setNewCondition={setNewCondition}
                        />
                      </div>

                      <div className="col-span-1 flex items-center">
                        <CustomDatePicker
                          selected={editingRowData?.expectedConfirmed || ""}
                          handleDateChage={handleExpectedEditeDate}
                          name="expectedConfirmed"
                          id="date"
                          setNewCondition={setNewCondition}
                        />

                        <div
                          ref={toggleRef}
                          className="relative mt-1 ml-4"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <div
                            onClick={handleToggleChangeEdit}
                            className="cursor-pointer flex justify-center items-center gap-2 w-full"
                          >
                            {editingRowData?.expectedConfirmedFlag ? (
                              <FontAwesomeIcon
                                icon={faToggleOn}
                                className="text-blue-primary text-[20px] "
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faToggleOff}
                                className="text-blue-primary text-[20px]"
                              />
                            )}
                          </div>
                          {isHovered && (
                            <Tooltip
                              targetRef={toggleRef}
                              text={
                                editingRowData?.expectedConfirmedFlag
                                  ? "When toggled, Expected/Confirmed date is marked as confirmed"
                                  : "When not toggled, Expected/Confirmed date remains as expected."
                              }
                            />
                          )}
                        </div>
                      </div>

                      <div className="col-span-1">
                        <CustomSelect
                          name="statusId"
                          placeholder="Select status"
                          value={
                            typeof editingRowData?.statusId === "number"
                              ? editingRowData?.statusId
                              : editingRowData?.statusId === "Complete"
                              ? 1
                              : 2
                          }
                          onChange={(e) =>
                            setEditingRowData((prev) =>
                              prev
                                ? { ...prev, statusId: Number(e.target.value) }
                                : null
                            )
                          }
                          options={[
                            { id: 2, name: "Pending" },
                            { id: 1, name: "Complete" },
                          ]}
                        />
                      </div>

                      <div className="w-[30px]">
                        <div
                          className=" cursor-pointer hidden"
                          id={`edit${item.id}`}
                        >
                          <img
                            src={DeleteIcon}
                            alt="delete"
                            onClick={() => openDeleteModal(item.id)}
                          />
                        </div>
                      </div>
                    </div>
                    {(isAddingCondition || editingRowId) && (
                      <div className="absolute top-[34px] right-[100px]">
                        <ConditionsAddCancelBtn
                          editingRowId={editingRowId}
                          saveEditedRow={saveEditedRow}
                          handleAddConditionToggle={handleAddConditionToggle}
                          setIsAddingCondition={setIsAddingCondition}
                          confirmButtonRef={confirmButtonRef}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    onMouseOver={() => {
                      toggleVisibility(`edit${item.id}`, true);
                    }}
                    onMouseLeave={() => {
                      toggleVisibility(`edit${item.id}`, false);
                    }}
                    key={`${index}conditions`}
                    onDoubleClick={() => startEditing(item)}
                    className="grid grid-cols-7   items-center gap-4 px-4 grid-flow-col auto-cols-auto border-b-1 last:border-b-0 hover:bg-grey-100"
                  >
                    <div className="pt-[12px] pb-[12px]   text-start flex items-center gap-1 cursor-pointer text-xs-normal col-span-1 ">
                      {conditionCategories.find(
                        (category:any) => category.id === item.conditionCategoryId
                      )?.name || item.category?.name}
                    </div>
                    <div className="pt-[12px] pb-[12px]   text-start flex items-center gap-1 cursor-pointer text-xs-normal col-span-3 ">
                      {item?.commentary}
                    </div>
                    <div className="pt-[12px] pb-[12px]   text-start flex items-center gap-1 cursor-pointer text-xs-normal col-span-1 ">
                      {item?.filed === null
                        ? null
                        : moment(formatAndValidateDate(item?.filed)).format(
                            "MM/DD/YYYY"
                          )}
                    </div>
                    <div className="pt-[12px] pb-[12px]   text-start flex items-center gap-1 cursor-pointer text-xs-normal col-span-1 ">
                      {item?.expectedConfirmed === null
                        ? null
                        : moment(
                            formatAndValidateDate(item?.expectedConfirmed)
                          ).format("MM/DD/YYYY")}
                      {"\u00A0"}
                      {item?.expectedConfirmed === null
                        ? null
                        : item?.expectedConfirmedFlag === true
                        ? "(C)"
                        : "(E)"}
                    </div>

                    <div className="pt-[12px] pb-[12px]   text-start flex items-center gap-1 cursor-pointer text-xs-normal col-span-1 ">
                      {typeof item?.statusId === "string"
                        ? item?.statusId
                        : item?.statusId === 2
                        ? "Pending"
                        : "Complete"}
                    </div>
                    <div className="flex justify-center cursor-pointer w-[30px]">
                      <img
                        id={`edit${item.id}`}
                        className="hidden"
                        src={DeleteIcon}
                        alt="delete"
                        onClick={() => openDeleteModal(item.id)}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConditionsMnaTable;
