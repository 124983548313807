// React
import React, { useEffect, useRef, useState } from "react";
// Third party
import moment from "moment";
// Components
import { addBrokerClosingDateLocally } from "../../../components/EditableFields/EditableFieldsUtilities/editableFieldsUtils";
// Types
import { Item } from "../../../interfaces/pages/variedInterfaces";
// Icons
import DeleteModalMNADates from "../../../components/Modal/DeleteModalMNADates";
import { RootState } from "../../../store/store";
import { useAppSelector } from "../../../store/storeHooks";
import { handleFormError } from "../../../utils/errorHandling";
import EditableDateField from "../../../components/EditableFields/EditableDateField";
import CommentEditor, {
  CommentEditorHandle,
} from "../../../components/EditableFields/EditableCommentField";
import closeImg from "../../../assets/svg/RedClose.svg";
import checkmark from "../../../assets/svg/GreenCheckmark.svg";
interface DatesProp {
  data: any;
  setFetchRequired: React.Dispatch<React.SetStateAction<boolean>>;
  setData: React.Dispatch<React.SetStateAction<any>>;
  fetchMnaData: () => void;
  safeId: any;
  setDateData: any;
  dateData: any;
}
export default function Dates({
  data,
  setFetchRequired,
  setData,
  fetchMnaData,
  safeId,
}: DatesProp) {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [openSubMenu, setOpenSubMenu] = useState<{
    type: "out" | "broker" | "finance" | null;
    index: number | null;
  }>({ type: null, index: null });
  const [activeMenu, setActiveMenu] = useState<{
    type: string | null;
    index: number | null;
  }>({
    type: null,
    index: null,
  });

  const [originalValues, setOriginalValues] = useState<{
    [key: string]: { comment?: string; dateEvent?: string };
  }>({});

  const handleRowActivation = (
    itemId: string,
    fieldName: string,
    item: Item
  ) => {
    setActiveRowId(itemId);
    setOriginalValues((prev) => ({
      ...prev,
      [itemId]: {
        comment: item.comment,
        dateEvent: item.dateEvent,
      },
    }));
  };

  const [dateToDelete, setDateToDelete] = useState<{
    id?: string;
    fieldName?: string;
  }>({
    id: "",
    fieldName: "",
  });

  useState<boolean>(true);
  const [activeSubMenu, setActiveSubMenu] = useState<{
    type: "out" | "broker" | "finance" | null;
    index: number | null;
  }>({ type: null, index: null });
  const [isSubmitting, setIsSubmitting] = useState<{ [key: string]: boolean }>(
    {}
  );

  const submenuRef = useRef<HTMLDivElement>(null);
  const commentRef = useRef<CommentEditorHandle>(null);
  const dateFieldRefs = useRef<{
    out: Array<any>;
    broker: Array<any>;
    finance: Array<any>;
  }>({ out: [], broker: [], finance: [] });

  const handleSubmitForIndex = (
    type: "out" | "broker" | "finance",
    index: number
  ) => {
    const key = `${type}-${index}`;
    if (isSubmitting[key]) return;
    setIsSubmitting((prev) => ({ ...prev, [key]: true }));
    dateFieldRefs.current[type][index]?.submit(
      dateFieldRefs.current[type][index]?.getValues().inputValue
    );

    setTimeout(() => {
      setIsSubmitting((prev) => {
        const newState = { ...prev };
        delete newState[key];
        return newState;
      });
    }, 2000);
  };

  const submenuRefs = useRef<{
    out: Array<HTMLDivElement | null>;
    broker: Array<HTMLDivElement | null>;
    finance: Array<HTMLDivElement | null>;
  }>({ out: [], broker: [], finance: [] });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if ((event.target as HTMLElement).closest("[data-ignore-click]")) {
        return;
      }
      const isOutside = Object.values(submenuRefs.current)
        .flat()
        .every((ref) => !ref?.contains(event.target as Node));
      if (isOutside) {
        setActiveSubMenu({ type: null, index: null });
        setOpenSubMenu({ type: null, index: null });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const cancelUnsavedValue = (fieldName: any, index?: number) => {
    setData((prevData: any) => {
      const updatedItems = prevData[fieldName]
        .map((item: Item, i: number) => {
          if (i === index && item.id === activeRowId) {
            return {
              ...item,
              comment: originalValues[item.id]?.comment ?? item.comment,
              dateEvent: originalValues[item.id]?.dateEvent ?? item.dateEvent,
            };
          }
          return item;
        })
        .filter((item: any) => !item.isNew);

      return { ...prevData, [fieldName]: updatedItems };
    });
    setActiveRowId(null);
  };

  const [toggleAddDate, setToggleAddDate] = useState<boolean>(false);
  const [showDetailsButton, setshowDetailsButton] = useState<string>("");
  const [commentValue, setCommentValue] = useState<any>("");
  const [openSubMenuIfDatesEmpty, setOpenSubMenuIfDatesEmpty] =
    useState<boolean>(false);

  const [activeRowId, setActiveRowId] = useState<string | null>(null);

  const mnaDetails = useAppSelector(
    (state: RootState) => state.mnaDetails.mnaDetails
  );

  const handleFetchMnaData = async () => {
    fetchMnaData();
  };

  const openDeleteModal = (deleteId: string | undefined, fieldName: string) => {
    setDateToDelete({ id: deleteId, fieldName });
    setIsDeleteModalOpen(true);
    setActiveSubMenu({ type: null, index: null });
  };

  const closeDeleteModal = () => {
    setDateToDelete({ id: "", fieldName: "" });
    setIsDeleteModalOpen(false);
  };
  const handleDeleteDate = async () => {
    if (!dateToDelete.id || !dateToDelete.fieldName) return;

    if (dateToDelete.id === "1") {
      setData((prevData: any) => ({
        ...prevData,
        [dateToDelete.fieldName!]: [
          ...prevData[dateToDelete.fieldName!].slice(0, -1),
        ],
      }));
      closeDeleteModal();
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${API_URL}/api/domain/mna/dates/${dateToDelete.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to delete Date");
      setFetchRequired(true);
    } catch (error) {
      handleFormError(error);
    } finally {
      closeDeleteModal();
    }
  };

  return (
    <div className="relative ">
      <div className="border-1 border-gray-200 rounded-radius-md overflow-visible p-4 bg-white min-h-[200px] ">
        <div className="rounded-bl-lg rounded-br-lg  dark:bg-table-dark  w-full">
          <div className="text-left text-blue-500 text-lg-medium pb-2">
            Dates
          </div>
          <div
            className={`rounded-[6px] grid grid-cols-12 justify-between items-center relative pb-2 px-2  ${
              data?.outDates?.length <= 0 ? "hover:bg-gray-100 " : ""
            } `}
          >
            <p className=" dark:text-white text-xs-medium text-left col-span-5 flex items-top h-full pt-[10px]">
              <span> Out Dates:</span>
            </p>

            {data?.outDates?.map((item: Item, index: number) => (
              <React.Fragment key={`out-dates-fragment-${item.id || index}`}>
                <div
                  onDoubleClick={() => {
                    handleRowActivation(item.id, "outDates", item);
                  }}
                  onMouseOver={() => {
                    if (activeRowId !== item.id && !item.isNew) {
                      setshowDetailsButton(item.id);
                    }
                  }}
                  onMouseLeave={() => {
                    setshowDetailsButton("");
                  }}
                  className={`col-span-8 col-start-6 flex justify-between -mr-2 px-2 py-2 ${
                    item.id !== activeRowId && !item.isNew
                      ? "hover:bg-grey-100"
                      : ""
                  }  rounded-[6px] relative`}
                >
                  <div className="w-[30%]  flex">
                    <EditableDateField
                      data={
                        item?.dateEvent
                          ? moment(item.dateEvent).isValid()
                            ? moment(item.dateEvent).format("MM/DD/YYYY")
                            : ""
                          : ""
                      }
                      fieldName="outDates"
                      dateIndex={index}
                      fullData={data}
                      setLocalData={setData}
                      dateItemValue={item.dateEvent}
                      fetchMnaData={handleFetchMnaData}
                      active={activeRowId === item.id || item.isNew == true}
                      setActiveRow={setActiveRowId}
                      rowId={item.id}
                      ref={(el: any) => (dateFieldRefs.current.out[index] = el)}
                      commentValue={item.comment || ""}
                      setCommentValue={setCommentValue}
                    />
                  </div>
                  <div className="w-[60%] flex   relative items-center justify-between">
                    <CommentEditor
                      ref={commentRef}
                      testId={`comment-OutDates-${index}`}
                      fieldName="outDates"
                      data={item?.comment}
                      fullData={data}
                      dateIndex={index}
                      setFetchMnaDetails={setFetchRequired}
                      setLocalData={setData}
                      fetchMnaData={handleFetchMnaData}
                      active={activeRowId === item.id || item.isNew == true}
                      setCommentValue={setCommentValue}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === "Enter") {
                          handleSubmitForIndex("out", index);
                        }
                      }}
                    />
                    {activeRowId === item.id || item.isNew == true ? (
                      <div className="flex space-x-1  ml-auto">
                        <button
                          type="submit"
                          disabled={isSubmitting[`out-${index}`]}
                          className="rounded-md p-1  bg-white  transition-shadow duration-200 border-1 border-gray-300 hover:border-green-500 shadow-shadow-sm focus:bg-gray-300 "
                          onClick={() => {
                            handleSubmitForIndex("out", index);
                          }}
                        >
                          <img
                            className="min-w-[13px] min-h-[13px]"
                            src={checkmark}
                            alt="checkmark"
                          />
                        </button>
                        <button
                          type="button"
                          onClick={() => cancelUnsavedValue("outDates", index)}
                          className="rounded-md p-1 z-10 bg-white  transition-shadow duration-200 border-1 border-gray-300 shadow-shadow-sm hover:border-red-500 focus:bg-gray-300"
                        >
                          <img
                            src={closeImg}
                            className="min-w-[13px] min-h-[13px]"
                            alt="close"
                          />
                        </button>
                      </div>
                    ) : null}
                    <div
                      className={`relative ml-[14px]  -mt-[4px]
                      ${
                        showDetailsButton === item.id && activeRowId !== item.id
                          ? "block"
                          : "hidden"
                      }
                     `}
                    >
                      <button
                        onClick={() => {
                          setActiveSubMenu((prev) =>
                            prev.type === "out" && prev.index === index
                              ? { type: null, index: null }
                              : { type: "out", index }
                          );
                          cancelUnsavedValue("outDates", index);
                        }}
                        className="cursor-pointer "
                      >
                        ...
                      </button>
                    </div>
                  </div>

                  {activeSubMenu.type === "out" &&
                    activeSubMenu.index === index && (
                      <div
                        ref={(el) => (submenuRefs.current.out[index] = el)}
                        className="absolute right-[0px] top-[40px] bg-white p-2 rounded shadow-md flex flex-col shadow-shadow-md z-[50]"
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();

                            const newId = "new-" + Date.now();
                            addBrokerClosingDateLocally(
                              "outDates",
                              safeId,
                              setData,
                              newId,
                              index
                            );
                            setActiveRowId(newId);

                            setActiveSubMenu((prev) =>
                              prev.type === "out"
                                ? { type: null, index: null }
                                : { type: "out", index: null }
                            );
                          }}
                          className="hover:bg-[#F3F8FF] px-4 py-1 rounded text-blue-900 hover:text-blue-500  text-xs-normal"
                        >
                          Add
                        </button>
                        {item.id && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              let itemId = mnaDetails?.outDates?.[index]?.id;
                              if (!itemId) {
                                itemId = "1";
                              }

                              openDeleteModal(
                                mnaDetails?.outDates?.[index]?.id,
                                "outDates"
                              );
                            }}
                            className="hover:bg-[#F3F8FF] px-4 py-1 rounded text-blue-900 hover:text-blue-500 text-xs-normal mt-1"
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    )}
                </div>
              </React.Fragment>
            ))}
            {data?.outDates?.length <= 0 && (
              <div className="col-start-12 justify-self-end cursor-pointer -mt-[0px] ">
                <button
                  className=""
                  onClick={() => {
                    setOpenSubMenu((prev) =>
                      prev.type === "out"
                        ? { type: null, index: null }
                        : { type: "out", index: null }
                    );
                    cancelUnsavedValue("outDates");
                  }}
                >
                  ...
                </button>
              </div>
            )}

            <div
              ref={submenuRef}
              className={`${
                openSubMenu.type === "out" ? "block" : "hidden"
              } absolute right-[0px] top-[36px] z-[50] bg-white  p-2 rounded-[6px] flex items-center justify-center shadow-shadow-md flex-col`}
            >
              <button
                data-ignore-click
                onClick={(e) => {
                  e.stopPropagation();

                  const newId = "new-" + Date.now();

                  setOpenSubMenu((prev) =>
                    prev.type === "out"
                      ? { type: null, index: null }
                      : { type: "out", index: null }
                  );
                  addBrokerClosingDateLocally(
                    "outDates",
                    safeId,
                    setData,
                    newId
                  );
                  setActiveRowId(newId);
                }}
                className="hover:bg-[#F3F8FF] px-4 py-1 rounded-[4px] text-blue-500 text-xs-normal"
              >
                Add
              </button>
            </div>
          </div>
          <div
            className={`rounded-[6px] grid grid-cols-12 justify-between items-center relative  px-2 pb-2  ${
              data?.brokerClosingDates?.length <= 0 ? "hover:bg-gray-100 " : ""
            }`}
          >
            {" "}
            <p className=" dark:text-white text-xs-medium text-left col-span-5 flex items-top h-full pt-[10px]">
              <span> Broker Closing Estimate Date:</span>
            </p>
            {data?.brokerClosingDates?.map((item: Item, index: number) => (
              <React.Fragment key={`broker-dates-fragment-${item.id || index}`}>
                <div
                  onDoubleClick={() => {
                    handleRowActivation(item.id, "brokerClosingDates", item);
                  }}
                  onMouseOver={() => {
                    if (activeRowId !== item.id && !item.isNew) {
                      setshowDetailsButton(item.id);
                    }
                  }}
                  onMouseLeave={() => {
                    setshowDetailsButton("");
                  }}
                  className={`col-span-8 col-start-6 flex justify-between -mr-2 px-2 py-2 ${
                    item.id !== activeRowId && !item.isNew
                      ? "hover:bg-grey-100"
                      : ""
                  } rounded-[6px] relative`}
                >
                  <div className="w-[30%]  flex">
                    <EditableDateField
                      data={
                        item?.dateEvent
                          ? moment(item.dateEvent).isValid()
                            ? moment(item.dateEvent).format("MM/DD/YYYY")
                            : ""
                          : ""
                      }
                      fieldName="brokerClosingDates"
                      dateIndex={index}
                      fullData={data}
                      setLocalData={setData}
                      dateItemValue={item.dateEvent}
                      fetchMnaData={handleFetchMnaData}
                      active={activeRowId === item.id || item.isNew == true}
                      setActiveRow={setActiveRowId}
                      rowId={item.id}
                      ref={(el: any) =>
                        (dateFieldRefs.current.broker[index] = el)
                      }
                      commentValue={item.comment || ""}
                      setCommentValue={setCommentValue}
                    />
                  </div>
                  <div className="w-[60%] flex relative items-center justify-between">
                    <CommentEditor
                      ref={commentRef}
                      testId={`comment-brokerClosingDates-${index}`}
                      fieldName="brokerClosingDates"
                      data={item?.comment}
                      fullData={data}
                      dateIndex={index}
                      setFetchMnaDetails={setFetchRequired}
                      setLocalData={setData}
                      fetchMnaData={handleFetchMnaData}
                      active={activeRowId === item.id || item.isNew == true}
                      setCommentValue={setCommentValue}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === "Enter") {
                          handleSubmitForIndex("broker", index);
                        }
                      }}
                    />
                    {activeRowId === item.id || item.isNew == true ? (
                      <div className="flex space-x-1 ml-auto">
                        <button
                          type="submit"
                          disabled={isSubmitting[`broker-${index}`]}
                          className="rounded-md p-1  bg-white  transition-shadow duration-200 border-1 border-gray-300 shadow-shadow-sm hover:border-green-500"
                          onClick={() => {
                            handleSubmitForIndex("broker", index);
                          }}
                        >
                          <img
                            className="min-w-[13px] min-h-[13px] "
                            src={checkmark}
                            alt="checkmark"
                          />
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            cancelUnsavedValue("brokerClosingDates", index)
                          }
                          className="rounded-md p-1  bg-white  transition-shadow duration-200 border-1 border-gray-300 shadow-shadow-sm hover:border-red-500 focus:bg-gray-300"
                        >
                          <img
                            src={closeImg}
                            className="min-w-[13px] min-h-[13px]"
                            alt="close"
                          />
                        </button>
                      </div>
                    ) : null}

                    <div
                      className={`relative ml-[14px] -mt-[4px] ${
                        showDetailsButton === item.id && activeRowId !== item.id
                          ? "block"
                          : "hidden"
                      }`}
                    >
                      <button
                        onClick={() => {
                          setActiveSubMenu((prev) =>
                            prev.type === "broker" && prev.index === index
                              ? { type: null, index: null }
                              : { type: "broker", index }
                          );
                          cancelUnsavedValue("brokerClosingDates", index);
                        }}
                        className="cursor-pointer "
                      >
                        ...
                      </button>
                    </div>
                  </div>

                  {activeSubMenu.type === "broker" &&
                    activeSubMenu.index === index && (
                      <div
                        ref={(el) => (submenuRefs.current.broker[index] = el)}
                        className="absolute right-[0px] top-[40px] bg-white p-2 rounded shadow-md flex flex-col shadow-shadow-md z-[50]"
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();

                            const newId = "new-" + Date.now();
                            addBrokerClosingDateLocally(
                              "brokerClosingDates",
                              safeId,
                              setData,
                              newId,
                              index
                            );
                            setActiveRowId(newId);

                            setActiveSubMenu((prev) =>
                              prev.type === "broker"
                                ? { type: null, index: null }
                                : { type: "broker", index: null }
                            );
                          }}
                          className="hover:bg-[#F3F8FF] px-4 py-1 rounded text-blue-900 hover:text-blue-500  text-xs-normal"
                        >
                          Add
                        </button>
                        {item.id && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              let itemId =
                                mnaDetails?.brokerClosingDates?.[index]?.id;
                              if (!itemId) {
                                itemId = "1";
                              }

                              openDeleteModal(
                                mnaDetails?.brokerClosingDates?.[index]?.id,
                                "brokerClosingDates"
                              );
                            }}
                            className="hover:bg-[#F3F8FF] px-4 py-1 rounded text-blue-900 hover:text-blue-500 text-xs-normal mt-1"
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    )}
                </div>
              </React.Fragment>
            ))}
            {data?.brokerClosingDates.length <= 0 && (
              <div className="col-start-12 justify-self-end cursor-pointer -mt-[3px] ">
                <button
                  className=""
                  onClick={() => {
                    setOpenSubMenu((prev) =>
                      prev.type === "broker"
                        ? { type: null, index: null }
                        : { type: "broker", index: null }
                    );
                    cancelUnsavedValue("brokerClosingDates");
                  }}
                >
                  ...
                </button>
              </div>
            )}
            <div
              ref={submenuRef}
              className={`${
                openSubMenu.type === "broker" ? "block" : "hidden"
              } absolute right-[0px] top-[36px] z-[50] bg-white  p-2 rounded-[6px] flex items-center justify-center shadow-shadow-md flex-col`}
            >
              <button
                data-ignore-click
                onClick={(e) => {
                  e.stopPropagation();
                  const newId = "new-" + Date.now();

                  setOpenSubMenu((prev) =>
                    prev.type === "broker"
                      ? { type: null, index: null }
                      : { type: "broker", index: null }
                  );
                  addBrokerClosingDateLocally(
                    "brokerClosingDates",
                    safeId,
                    setData,
                    newId
                  );
                  setActiveRowId(newId);
                }}
                className="hover:bg-[#F3F8FF] px-4 py-1 rounded-[4px] text-blue-500 text-xs-normal"
              >
                Add
              </button>
            </div>
          </div>
          <div
            className={`rounded-[6px] grid grid-cols-12 justify-between items-center relative pb-2 px-2   ${
              data?.financingDates?.length <= 0 ? "hover:bg-gray-100 " : ""
            }`}
          >
            {" "}
            <p className=" dark:text-white text-xs-medium text-left col-span-5 flex items-top h-full pt-[10px]">
              <span>Financing Date:</span>
            </p>
            {data?.financingDates?.map((item: Item, index: number) => (
              <React.Fragment
                key={`finance-dates-fragment-${item.id || index}`}
              >
                <div
                  onDoubleClick={() => {
                    handleRowActivation(item.id, "financingDates", item);
                  }}
                  onMouseOver={() => {
                    if (activeRowId !== item.id && !item.isNew) {
                      setshowDetailsButton(item.id);
                    }
                  }}
                  onMouseLeave={() => {
                    setshowDetailsButton("");
                  }}
                  className={`col-span-8 col-start-6 flex justify-between -mr-2 px-2 py-2 ${
                    item.id !== activeRowId && !item.isNew
                      ? "hover:bg-grey-100"
                      : ""
                  } rounded-[6px] relative`}
                >
                  <div className="w-[30%]  flex">
                    <EditableDateField
                      data={
                        item?.dateEvent
                          ? moment(item.dateEvent).isValid()
                            ? moment(item.dateEvent).format("MM/DD/YYYY")
                            : ""
                          : ""
                      }
                      fieldName="financingDates"
                      dateIndex={index}
                      fullData={data}
                      setLocalData={setData}
                      dateItemValue={item.dateEvent}
                      fetchMnaData={handleFetchMnaData}
                      active={activeRowId === item.id || item.isNew == true}
                      setActiveRow={setActiveRowId}
                      rowId={item.id}
                      ref={(el: any) =>
                        (dateFieldRefs.current.finance[index] = el)
                      }
                      commentValue={item.comment || ""}
                      setCommentValue={setCommentValue}
                    />
                  </div>
                  <div className="w-[60%] flex relative items-center justify-between">
                    {" "}
                    <CommentEditor
                      ref={commentRef}
                      testId={`financingDates-${index}`}
                      fieldName="financingDates"
                      data={item?.comment}
                      fullData={data}
                      dateIndex={index}
                      setFetchMnaDetails={setFetchRequired}
                      setLocalData={setData}
                      fetchMnaData={handleFetchMnaData}
                      active={activeRowId === item.id || item.isNew == true}
                      setCommentValue={setCommentValue}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === "Enter") {
                          handleSubmitForIndex("finance", index);
                        }
                      }}
                    />
                    {activeRowId === item.id || item.isNew == true ? (
                      <div className="flex space-x-1 ml-auto">
                        <button
                          type="submit"
                          disabled={isSubmitting[`finance-${index}`]}
                          className="rounded-md p-1 z-10 bg-white  transition-shadow duration-200 border-1 border-gray-300 shadow-shadow-sm hover:border-green-500"
                          onClick={() => {
                            handleSubmitForIndex("finance", index);
                          }}
                        >
                          <img
                            className="min-w-[13px] min-h-[13px]"
                            src={checkmark}
                            alt="checkmark"
                          />
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            cancelUnsavedValue("financingDates", index)
                          }
                          className="rounded-md p-1 z-10 bg-white  transition-shadow duration-200 border-1 border-gray-300 shadow-shadow-sm hover:border-red-500 focus:bg-gray-300"
                        >
                          <img
                            src={closeImg}
                            className="min-w-[13px] min-h-[13px]"
                            alt="close"
                          />
                        </button>
                      </div>
                    ) : null}
                    <div
                      className={`relative ml-[14px] -mt-[4px] ${
                        showDetailsButton === item.id && activeRowId !== item.id
                          ? "block"
                          : "hidden"
                      }`}
                    >
                      <button
                        onClick={() => {
                          setActiveSubMenu((prev) =>
                            prev.type === "finance" && prev.index === index
                              ? { type: null, index: null }
                              : { type: "finance", index }
                          );
                          cancelUnsavedValue("financingDates", index);
                        }}
                        className="cursor-pointer "
                      >
                        ...
                      </button>
                    </div>
                  </div>

                  {activeSubMenu.type === "finance" &&
                    activeSubMenu.index === index && (
                      <div
                        ref={(el) => (submenuRefs.current.finance[index] = el)}
                        className="absolute right-[0px] top-[40px] bg-white p-2 rounded shadow-md flex flex-col shadow-shadow-md z-50"
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();

                            const newId = "new-" + Date.now();
                            addBrokerClosingDateLocally(
                              "financingDates",
                              safeId,
                              setData,
                              newId,
                              index
                            );
                            setActiveRowId(newId);

                            setActiveSubMenu((prev) =>
                              prev.type === "finance"
                                ? { type: null, index: null }
                                : { type: "finance", index: null }
                            );
                          }}
                          className="hover:bg-[#F3F8FF] px-4 py-1 rounded text-blue-900 hover:text-blue-500  text-xs-normal"
                        >
                          Add
                        </button>
                        {item.id && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              let itemId =
                                mnaDetails?.financingDates?.[index]?.id;
                              if (!itemId) {
                                itemId = "1";
                              }

                              openDeleteModal(
                                mnaDetails?.financingDates?.[index]?.id,
                                "financingDates"
                              );
                            }}
                            className="hover:bg-[#F3F8FF] px-4 py-1 rounded text-blue-900 hover:text-blue-500 text-xs-normal mt-1"
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    )}
                </div>
              </React.Fragment>
            ))}
            {data?.financingDates.length <= 0 && (
              <div className="col-start-12 justify-self-end cursor-pointer -mt-[3px] ">
                <button
                  className=""
                  onClick={() => {
                    setOpenSubMenu((prev) =>
                      prev.type === "finance"
                        ? { type: null, index: null }
                        : { type: "finance", index: null }
                    );
                    cancelUnsavedValue("financingDates");
                  }}
                >
                  ...
                </button>
              </div>
            )}
            <div
              ref={submenuRef}
              className={`${
                openSubMenu.type === "finance" ? "block" : "hidden"
              } absolute right-[0px] top-[36px] z-[50] bg-white  p-2 rounded-[6px] flex items-center justify-center shadow-shadow-md flex-col`}
            >
              <button
                data-ignore-click
                onClick={(e) => {
                  e.stopPropagation();

                  const newId = "new-" + Date.now();

                  setOpenSubMenu((prev) =>
                    prev.type === "finance"
                      ? { type: null, index: null }
                      : { type: "finance", index: null }
                  );
                  addBrokerClosingDateLocally(
                    "financingDates",
                    safeId,
                    setData,
                    newId
                  );
                  setActiveRowId(newId);
                }}
                className="hover:bg-[#F3F8FF] px-4 py-1 rounded-[4px] text-blue-500 text-xs-normal"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      {isDeleteModalOpen && (
        <DeleteModalMNADates
          title="Are you sure you want to delete this date?"
          onClose={closeDeleteModal}
          onConfirm={handleDeleteDate}
        />
      )}
    </div>
  );
}
