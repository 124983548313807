import moment from "moment-timezone";

const dateEventTypeList = [
  { value: 1, label: "Out Date" },
  { value: 2, label: "Broker Closing Estimate Date" },
  { value: 3, label: "Financing Date" },
];

const statusCondition = [
  { value: 1, label: "Complete" },
  { value: 2, label: "Pending" },
];

const sentimentList = [
  { value: -1, label: "Negative" },
  { value: 0, label: "Neutral" },
  { value: 1, label: "Positive" },
];

const checkMnaAlertName = (
  value: any,
  name: string,
  categoryList: any[],
  sectorListMna: any[]
): string => {
  if (name === "dateAnnounced" || name === "dateClosing") {
    return formatDate(value);
  }

  if (name === "dateCreated" || name === "dateModified") {
    return formatModifiedDate(value);
  }

  if (name === "requiredToLitigate") {
    if (value === true) {
      return "Yes";
    } else if (value === false) {
      return "No";
    } else {
      return "-";
    }
  }

  if (name === "sectorTarget" || name === "sectorAcquirer") {
    const sector = sectorListMna.find((item) => item.id == value);
    return sector ? sector.name : String(value || "");
  }

  if (name === "status") {
    if (!value?.cdoId) return "";

    const sector = statusCondition.find((item) => item.value == value.cdoId);
    return sector ? sector.label : String(value.cdoId || "");
  }

  if (name === "category") {
    if (!value?.cdoId) return "";
    const category = categoryList.find((item) => item.id === value.cdoId);
    return category ? category.name : String(value.cdoId || "");
  }
  return typeof value === "object"
    ? String(value?.cdoId || "")
    : String(value || "");
};

const checkNoteAlertName = (
  value: any,
  name: string,
  sourceType: any[] = []
): string => {
  if (
    name === "datePublished" ||
    name === "dateCreated" ||
    name === "dateModified"
  ) {
    return formatModifiedDate(value);
  }
  if (name === "sourceType") {
    if (!value?.cdoId) return "";
    const sourceTypeList = sourceType.find((item) => item.id == value.cdoId);
    return sourceTypeList ? sourceTypeList.name : String(value.cdoId || "");
  }
  if (name === "sentiment") {
    if (typeof value === "number") {
      const sentiment = sentimentList.find((item) => item.value === value);
      return sentiment ? sentiment.label : String(value);
    }
    if (value?.cdoId !== undefined) {
      const sentiment = sentimentList.find(
        (item) => item.value === value.cdoId
      );
      return sentiment ? sentiment.label : String(value.cdoId);
    }
    return String(value || "");
  }
  return typeof value === "object"
    ? String(value?.cdoId || "")
    : String(value || "");
};

const checkConditionAlertName = (
  value: any,
  name: string,
  conditionCategories: any
): string => {
  if (name === "category") {
    if (!value?.cdoId) return "";
    const category = conditionCategories?.find(
      (item: any) => item.id == value.cdoId
    );
    return category ? category?.name : String(value.cdoId || "");
  } else if (name == "mna") {
    return name;
  } else if (name == "status") {
    if (!value?.cdoId) return "";

    const sector = statusCondition.find((item) => item.value == value.cdoId);
    return sector ? sector.label : String(value.cdoId || "");
  } else if (name === "dateCreated" || name === "dateModified") {
    return formatModifiedDate(value);
  } else if (name === "expectedConfirmed" || name === "filed") {
    return formatDate(value);
  } else if (name === "expectedConfirmedFlag") {
    return value === true ? "Yes" : "No";
  }
  return typeof value === "object"
    ? String(value?.cdoId || "")
    : String(value || "");
};

const checkWhatMattersAlertName = (value: any, name: string): string => {
  if (name === "dateCreated" || name === "dateModified") {
    return formatModifiedDate(value);
  }
  return typeof value === "object"
    ? String(value?.cdoId || "")
    : String(value || "");
};

const checkFileAlertName = (value: any, name: string): string => {
  if (name === "dateCreated" || name === "dateModified") {
    return formatModifiedDate(value);
  }
  if (name == "size") {
    if (!value) return "";

    const fileSizeInKB = value / 1024;
    const fileSizeInMB = fileSizeInKB / 1024;
    const size =
      fileSizeInMB < 1
        ? `${fileSizeInKB.toFixed(2)} KB`
        : `${fileSizeInMB.toFixed(2)} MB`;

    return size;
  }
  return typeof value === "object"
    ? String(value?.cdoId || "")
    : String(value || "");
};

const checkLinkAlertName = (
  value: any,
  name: string,
  linkCategories: any[]
): string => {
  if (name === "dateCreated" || name === "dateModified") {
    return formatModifiedDate(value);
  }
  if (name === "createdBy" || name === "lastModifiedBy") {
    if (!value?.username) return "";
    return String(value.username);
  }

  if (name === "category") {
    if (!value?.cdoId) return "";
    const category = linkCategories.find((item) => item.id === value.cdoId);
    return category ? category.name : String(value.cdoId || "");
  }

  return typeof value === "object"
    ? String(value?.cdoId || "")
    : String(value || "");
};

const checkCompanyAlertName = (value: any, name: string): string => {
  return typeof value === "object"
    ? String(value?.cdoId || "")
    : String(value || "");
};

const checkFundamentalAlertName = (
  value: any,
  name: string,
  sectorListFundamental: any[] = []
): string => {
  if (name === "dateIpo") {
    return formatDate(value);
  }
  if (name === "exchange") {
    if (!value?.cdoId) return "";
    return value.cdoId;
  }
  if (name === "dateCreated" || name === "dateModified") {
    return formatModifiedDate(value);
  }
  if (name === "sector") {
    if (!value?.cdoId) return "";
    const category = sectorListFundamental.find(
      (item) => item.id === value.cdoId
    );
    return category ? category.name : String(value.cdoId);
  }
  return value;
};

const checkDateEventAlertName = (value: any, name: string): string => {
  if (name === "dateCreated" || name === "dateModified") {
    return formatModifiedDate(value);
  }
  if (name === "dateEvent") {
    return formatDate(value);
  }
  if (name === "dateEventType") {
    if (!value?.cdoId) return "";
    const eventType = dateEventTypeList.find(
      (item) => item.value == value.cdoId
    );
    return eventType ? eventType.label : value;
  }
  return value;
};

const checkRegulatoryAlertName = (value: any, name: string): string => {
  if (name === "dateCreated" || name === "dateModified") {
    return formatModifiedDate(value);
  }
  if (name === "lastModifiedBy") {
    return value.username;
  }
  return value;
};

export const checkAlertName = (
  alertDomainType: number,
  name: string,
  value: any,
  conditionCategories?: any[],
  sourceType?: any[],
  sectorListFundamental?: any[],
  categoryList?: any[],
  linkCategories?: any[],
  sectorListMna?: any[]
): string => {
  switch (alertDomainType) {
    case 1:
      return checkMnaAlertName(
        value,
        name,
        categoryList || [],
        sectorListMna || []
      );
    case 2:
      return checkNoteAlertName(value, name, sourceType);
    case 3:
      return checkConditionAlertName(value, name, conditionCategories);
    case 4:
      return checkWhatMattersAlertName(value, name);
    case 6:
      return checkFileAlertName(value, name);
    case 7:
      return checkLinkAlertName(value, name, linkCategories || []);
    case 8:
      return checkCompanyAlertName(value, name);
    case 9:
      return checkFundamentalAlertName(value, name, sectorListFundamental);
    case 10:
      return checkDateEventAlertName(value, name);
    case 11:
      return checkRegulatoryAlertName(value, name);
  }
  return value;
};

export const formatDate = (dateString: string) => {
  if (!dateString) {
    return "";
  }
  const [year, month, day] = dateString.split("-");
  return `${month}/${day}/${year}`;
};

export const formatModifiedDate = (value: string): string => {
  if (!value) {
    return "";
  }
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment.utc(value).tz(userTimeZone).format("MM/DD/YY hh:mm:ssA");
};
