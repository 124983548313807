import { useQuery } from "@tanstack/react-query";
import { getFundamentalById } from "../pages/MnaAddEditMiniForm/services/MiniFormServices";

export function useFundamental(cdoId: string | undefined) {
  return useQuery({
    queryKey: ["fundamental", cdoId],
    queryFn: () => {
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      if (!cdoId || !uuidRegex.test(cdoId)) {
        console.warn("Invalid fundamental cdoId:", cdoId);
        return null;
      }
      return getFundamentalById(cdoId);
    },
    enabled: !!cdoId, 
    staleTime: 10 * 60 * 1000, 
    gcTime: 30 * 60 * 1000, 
    retry: 1, 
    refetchOnWindowFocus: false, 
    refetchOnMount: false, 
  });
}
