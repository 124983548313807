// React
import { useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
// OpenAPI
import { NoteResponse } from "../../openapi";
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
// Types
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import DomainList from "./components/DomainList";
import TagList from "./components/TagList";
import FilesList from "./components/FileList";
import DateDetails from "./components/DateDetails";
import NoteMetadata from "./components/NoteMetadata";
// Utilities
import { convertPictureToDiv } from "./utilities/noteUtils";
// Types
import { HandleMenuProps } from "../../interfaces/pages/variedInterfaces";
// Services
import { fetchNoteDataApi } from "./services/noteServices";
import Loader from "../../components/Loader";
import edit from "../../assets/svg/edit.svg";
export default function NoteDetails({
  isMenuOpen,
  setIsMenuOpen,
}: HandleMenuProps) {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [notesData, setNotesData] = useState<any>();
  const [domains, setDomains] = useState<
    { name: string; id: number; typeId: number }[]
  >([]);
  const [tags, setTags] = useState<string[]>([]);
  const navigate = useNavigate();
  const fetchNoteData = async () => {
    setLoading(true);
    try {
      const response = await fetchNoteDataApi(String(id));
      if (response.data) {
        const data: NoteResponse = response.data;
        setNotesData(data);
        setDomains(
          (data as any)?.domains?.map((domain: any) => {
            return {
              name: domain?.name,
              id: domain?.entityId,
              typeId: domain.typeId,
              domainName: domain.domainName,
            };
          })
        );
        setTags(
          data?.tags?.map((tag) => {
            return tag.tag;
          }) || []
        );
      }
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNoteData();
  }, [id]);

  const convertedContent = convertPictureToDiv(notesData?.content || "");

  useEffect(() => {
    const contentBlock = document.querySelector(".ql-editor");
    if (contentBlock) {
      contentBlock.classList.add("no-global-styles");
    }

    return () => {
      if (contentBlock) {
        contentBlock.classList.remove("no-global-styles");
      }
    };
  }, [notesData]);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <div
        className={`py-4 pr-4 ${
          isMenuOpen ? "pl-[300px]" : "pl-[76px]"
        } duration-500`}
      >
        <div className="mb-4">
          <Breadcrumbs
            breadcrumbs={["Notes", notesData?.title || ""]}
            icon={""}
            updateItem={() => navigate(`/notes/${id}/edit`)}
          />
        </div>
        <div className="w-full bg-gray-200 dark:bg-gray-900  rounded-radius-xl shadow-shadow-md">
          <div className="container-fluid mx-auto flex items-start justify-center ">
            <div className="w-full ">
              <div className=" mx-auto w-full border border-[#E6E6F9] bg-[#fff] rounded-md  dark:bg-button-dark ">
                <div className=" justify-between p-4 flex flex-col lg:flex-row items-start lg:items-center bg-[#F9F9FB] rounded-t-md dark:bg-sub-table-light dark:text-white">
                  <DomainList domains={domains} />
                  <div className="flex items-center gap-2">
                  <TagList tags={tags} />
                  <button
                    className="text-secondary font-semibold text-base capitalize underline float-right min-w-[30px]"
                    onClick={() => navigate(`/notes/${id}/edit`)}
                    data-testid="edit-note"
                  >
                    <div className="rounded-full p-[8px] border border-none bg-[#FFF4CA] cursor-pointer">
                      <img
                        className="h-[14px] w-[14px]"
                        src={edit}
                        alt="edit note"
                      />
                      </div>
                    </button>
                  </div>
                </div>
                <div className="justify-between flex flex-col lg:flex-row items-start lg:items-center px-4 pt-4">
                  <div className="w-full lg:w-1/2 pr-0 lg:pr-48">
                    <h1 className="cursor-pointer text-primary-dark dark:text-primary-light font-bold text-base">
                      {notesData?.title}
                    </h1>
                    <p className="mt-2 text-base text-primary-dark dark:text-primary-light font-bold">
                      {notesData?.sourceType}
                    </p>
                  </div>
                  <div className="w-full lg:w-1/2">
                    <DateDetails notesData={notesData} />
                    <NoteMetadata notesData={notesData} />
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row pl-4 pb-4 ">
                  <div className="flex flex-col lg:flex-row w-full lg:w-11/12 items-start lg:items-center mb-8 lg:mb-0">
                    <div
                      className="ql-container ql-snow"
                      style={{ position: "relative" }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: convertedContent || "",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                {notesData?.files?.length! > 0 && (
                  <FilesList
                    files={notesData.files}
                    startLoading={setLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
