import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import { Sort } from "../../../interfaces/pages/variedInterfaces";
import { RegulatoryDomainApi } from "../../../openapi/api";
import { getApiData } from "../../../controller/getApiDataConfiguration";

export const fetchRegulatoryData = async (id: string) => {
  try {
    const api = new RegulatoryDomainApi(apiConfig());
    const response = await api.getRegulatoryById(id);
    return response?.data || {};
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
};

export const fetchRegulatoryNoteList = async (
  id: string,
  pageIndex: number,
  pageSize: number,
  sortBy: Sort[]
) => {
  try {
    const getFetchRegulatoryNoteList = new getApiData();
    return getFetchRegulatoryNoteList.getNoteDomainApi2(
      id,
      pageIndex,
      pageSize,
      sortBy,
      "Regulatory"
    );
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
};
