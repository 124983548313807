import React from "react";

const FinancialSummary = () => {
  return (
    <div className=" overflow-x-auto rounded-radius-xl">
      <div className=" min-w-full inline-block align-middle">
        <div className="table-container">
          <table className="min-w-full dark:bg-primary-dark bg-white">
            <thead>
              <tr>
                <th className="pl-4 pr-3.5 pb-2 pt-4 text-lg-semibold bg-white text-blue-500 text-left">
                  Financial Summary
                </th>
                <th className="px-3.5 pb-2 pt-4 text-xs-normal text-[#B5B5B5] text-left">
                  Downside
                </th>
                <th className="px-3.5 pb-2 pt-4 text-xs-normal text-[#B5B5B5] text-left">
                  Base
                </th>
                <th className="px-3.5 pb-2 pt-4 text-xs-normal text-[#B5B5B5] text-left">
                  Upside
                </th>
              </tr>
            </thead>
            <tbody className="">
              <tr>
                <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
                  Simple Target:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $120.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $160.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $180.00
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
                  Divident/Other:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  0.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  0.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  0.00
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
                  Total Return:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $120.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $160.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $180.00
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
                  Nominal % Return:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  -10.00%
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  -16.00%
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  -180.00%
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
                  Probability:
                </td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left"></td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left"></td>
                <td className="pr-4 pl-3.5 text-[#000817] dark:text-white text-base font-medium text-left"></td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
                  Risk/Reward:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $120.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $160.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $180.00
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 pt-2 pb-4 text-sm-normal text-blue-900">
                  Annuluzed Return:
                </td>
                <td className="px-3.5 pb-2 text-xs-normal text-blue-900 text-left">
                  $120.00
                </td>
                <td className="px-3.5 pb-2 text-xs-normal text-blue-900 text-left">
                  $160.00
                </td>
                <td className="px-3.5 pb-2 text-xs-normal text-blue-900 text-left">
                  $180.00
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FinancialSummary;
