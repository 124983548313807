// React
import React, { useCallback, useEffect, useState } from "react";
// Components
import Table2 from "../../components/Tables/AlertTable";
import AlertContentModal from "./components/AlertContentModal";
// Types
import { HandleMenuProps } from "../../interfaces/pages/variedInterfaces";
// Images
import ChevronUp from "../../assets/svg/chevron-up.svg";
import ChevronDown from "../../assets/svg/chevron-down.svg";
import Search from "../../assets/svg/search_light_grey.svg";

import NotesAlert from "../../assets/svg/alerts/notes-alert.svg";
import MnaAlert from "../../assets/svg/alerts/mna-alert.svg";
import FundamentalAlert from "../../assets/svg/alerts/fundamental-alert.svg";
import RegulatoryAlert from "../../assets/svg/alerts/regulatory-alert.svg";
import FileAlert from "../../assets/svg/alerts/file-alert.svg";
// Formik
import { useFormik } from "formik";

// Services
import {
  AlertDiffResponse,
  AlertDomainApi,
  AlertsViewApi,
} from "../../openapi";
import { apiConfig } from "../../components/ConfigurationApi/Configuration";
import Loader from "../../components/Loader";

const AlertList: React.FC<HandleMenuProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string | undefined>();
  const [isTabMenuOpen, setTabMenuOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [alertsList, setAlertsList] = useState<AlertDiffResponse[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const alertData = [
    {
      id: 1,
      name: "MNA",
      color: "#0E9F6E",
      icon: MnaAlert,
    },
    {
      id: 2,
      name: "Note",
      color: "#E3A008",
      icon: NotesAlert,
    },
    {
      id: 3,
      name: "Condition",
      color: "#0E9F6E",
      icon: MnaAlert,
    },
    {
      id: 4,
      name: "WhatMatters",
      color: "#0E9F6E",
      icon: MnaAlert,
    },
    {
      id: 6,
      name: "File",
      color: "#FF8A4C",
      icon: FileAlert,
    },
    {
      id: 7,
      name: "Link",
      color: "#0E9F6E",
      icon: MnaAlert,
    },
    {
      id: 8,
      name: "Company",
      color: "#6875F5",
      icon: FundamentalAlert,
    },
    {
      id: 9,
      name: "Fundamental",
      color: "#6875F5",
      icon: FundamentalAlert,
    },
    {
      id: 10,
      name: "DateEvent",
      color: "#0E9F6E",
      icon: MnaAlert,
    },
    {
      id: 11,
      name: "Regulatory",
      color: "#F05252",
      icon: RegulatoryAlert,
    },
  ];

  const fetchAlertsHistory = async (pageIndex: number, pageSize: number) => {
    try {
      setLoading(true);
      const api = new AlertsViewApi(apiConfig());
      const response = await api.getAlertsWithDiff(pageIndex, pageSize);
      setAlertsList(response.data.content);
      return {
        content: response.data.content,
        page: {
          totalPages: response.data.page?.totalPages,
          totalElements: response.data.page?.totalElements,
        },
      };
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const fetchAlertList = useCallback(
    async (pageIndex: number, pageSize: number) => {
      return await fetchAlertsHistory(pageIndex, pageSize);
    },
    []
  );

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      Header: "Alert Details",
      accessor: "details",
      Cell: ({ row }: any) => {
        const alert = row.original;
        const alertDataItem = alertData.find(
          (a: any) => a.id === alert.domainTypeId
        );
        return (
          <div></div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      {loading && <Loader loading={loading} />}

      <div
        className={`py-[16px] pr-[16px] mb-[50px]  ${
          isMenuOpen ? "pl-[314px]" : "pl-[90px]"
        } duration-500`}
      >
        <div className="bg-white -mt-4 -ml-8 -mr-4 p-4 flex border-b-1">
          <p className="text-lg-semibold text-blue-500">Alerts</p>
          <div
            className="ml-2 cursor-pointer"
            onClick={() => {
              setTabMenuOpen((prev: boolean) => !prev);
            }}
          >
            <img src={isTabMenuOpen ? ChevronUp : ChevronDown} />
          </div>
        </div>
        {isTabMenuOpen ? (
          <div className="bg-white px-6 py-2 -ml-8 -mr-4 shadow-shadow-sm flex items-start gap-[26px] ">
            <form className="relative" onSubmit={formik.handleSubmit}>
              <div className="relative">
                <input
                  className="pl-10 pr-4 py-2 border border-blue-500 rounded-radius-md text-sm-normal focus:outline-none focus:ring-1 focus:ring-blue-500"
                  type="text"
                  id="search"
                  name="search"
                  onChange={formik.handleChange}
                  value={formik.values.search}
                  placeholder="Search here"
                />
                <img
                  src={Search}
                  alt="search"
                  className="absolute top-1/2 left-3 transform -translate-y-1/2"
                />
              </div>
            </form>
            <div className="flex flex-wrap gap-[26px]">
              {alertsList?.map((item, index) => {
                const isSelected = selectedId === index + 1;
                return (
                  <div
                    key={item.entityId}
                    className={`flex ${
                      isSelected ? "bg-blue-500" : ""
                    } p-[10px] rounded-md cursor-pointer`}
                    onClick={() => setSelectedId(index + 1)}
                  >
                    <p
                      className={`mr-2 whitespace-nowrap text-sm-medium ${
                        isSelected ? "text-white" : "text-blue-900"
                      }`}
                    >
                      {/* {item.name} */}
                    </p>
                    <p
                      className={`px-[10px] rounded-radius-2xl text-sm-medium ${
                        isSelected ? "bg-blue-400 text-white" : "bg-[#F1F2F4]"
                      } text-blue-900`}
                    >
                      {/* {item.alertsCount} */}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        <div className="mt-[6px] ">
          <Table2
            columns={columns}
            fetchData={fetchAlertList}
            additionalDataKey="content"
            // openModal={openModal}
            typeForPagination="setAlertPageIndex"
            isMenuOpen={isMenuOpen}
            alertData={alertData}
          />
        </div>
      </div>
      <AlertContentModal
        isOpen={isModalOpen}
        content={content}
        onClose={closeModal}
      />
    </React.Fragment>
  );
};

export default AlertList;
