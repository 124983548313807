import { useQueries, useQueryClient } from "@tanstack/react-query";
import { fetchMnaData } from "../pages/MnaDetails/services/mnaService";

export function useMnaPreload(mnaIds: string[]) {
  const queryClient = useQueryClient();

  useQueries({
    queries: mnaIds.map((id) => ({
      queryKey: ["mna", id],
      queryFn: () => fetchMnaData(id),
      staleTime: 10 * 60 * 1000, 
      gcTime: 30 * 60 * 1000, 
      enabled: !!id,
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    })),
  });

  const getMnaCached = (id: string) => {
    return queryClient.getQueryData(["mna", id]);
  };

  return { getMnaCached };
}
